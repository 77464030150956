import React from 'react';
import { Link } from 'react-router-dom';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const ServicesDetailsArea = ({ service }) => {
  return (
    <>
      <div className="service-details-area pt-120 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <article className="service-details-box">
                <div className="service-details-thumb mb-80">
                  <img className="img" src={service.mainImage} alt={service.title} />
                </div>
                <div className="section-title pos-rel mb-45">
                  <div className="section-icon">
                    <img className="section-back-icon back-icon-left" src="/img/section/section-back-icon-sky.png" alt="" />
                  </div>
                  <div className="section-text pos-rel">
                    <h5 className="green-color text-up-case">{service.title}</h5>
                    <h1>{service.description}</h1>
                  </div>
                  <div className="section-line pos-rel">
                    <img src="/img/shape/section-title-line.png" alt="Section Line" />
                  </div>
                </div>

                {service.headings.map((heading, index) => (
  <div className="service-details-text mb-30" key={`heading-${index}`}>
    {/* Render the Main H2 Heading */}
    {heading.type === 'h2' && <h2 className='mb-20'>{heading.text}</h2>}

    {/* Loop through the content of each heading and render accordingly */}
    {heading.content.map((item, contentIndex) => (
      <div key={`content-${index}-${contentIndex}`}>
        {/* If item is an H3, render it */}
        {item.type === 'h3' && item.text && <h3 className='mt-15 mb-10'>{item.text}</h3>}

        {/* If item is a paragraph, render it */}
        {item.type === 'paragraph' && (
          <p>
            {item.parts ? (
              // If the paragraph has parts (with links), render each part dynamically
              item.parts.map((part, partIndex) => (
                <React.Fragment key={`part-${index}-${contentIndex}-${partIndex}`}>
                  {/* Render Link if Specified */}
                  {part.link ? (
                    <a href={part.link.href} className="text-link">
                      {part.link.text}
                    </a>
                  ) : (
                    part.text
                  )}
                </React.Fragment>
              ))
            ) : (
              // If no parts, render the whole paragraph as text
              item.text
            )}
          </p>
        )}

        {/* If item is a bullet list, render it */}
        {item.type === 'list' && (
          <ul>
            {item.listItems.map((listItem, listIndex) => (
              <li key={`listItem-${index}-${contentIndex}-${listIndex}`}>{listItem}</li>
            ))}
          </ul>
        )}

        {/* If a secondary H2 is specified, render it directly below */}
        {item.type === 'h2' && item.text && (
          <div className="service-details-text mb-30">
            <h2>{item.text}</h2>
          </div>
        )}
      </div>
    ))}
  </div>
))}




                
                
                {/* Render Features in Two Columns */}
                <div className="service-details-feature fix mb-35">
                  {service.features.map((feature, index) => (
                    <div className="ser-fea-box f-left" key={index}>
                      <div className="ser-fea-icon f-left">
                        <img src={feature.icon} alt={feature.title} />
                      </div>
                      <div className="ser-fea-list fix">
                        <h3>{feature.title}</h3>
                        <ul>
                          {feature.items.map((item, itemIndex) => (
                            <li key={itemIndex}><i className="fas fa-check"></i>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Chart Section */}
                {/* <div className="service-chart mb-55">
                  <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                      width={500}
                      height={400}
                      data={service.chartData}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                      <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                      <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
                    </AreaChart>
                  </ResponsiveContainer>
                </div> */}

                {/* Testimonials Section */}
                <div className="testi-box text-center pos-rel mb-0">
                  <div className="testi-content testi-service-content pos-rel">
                    <div className="testi-quato-icon">
                      <img src="/img/testimonials/testi-quato-icon.png" alt="Quote Icon" />
                    </div>
                    <p>{service.testimonials.text}</p>
                    <span></span>
                  </div>
                  <div className="testi-author">
                    <h2 className="testi-author-title">{service.testimonials.author}</h2>
                    <span className="testi-author-desination">{service.testimonials.position}</span>
                  </div>
                  <div className="test-author-icon mb-10">
                    {/* <img src={service.testimonials.image} alt={service.testimonials.author} /> */}
                  </div>
                </div>

                {/* Download Section */}
                {/* <div className="section-title pos-rel mb-50">
                  <div className="section-text pos-rel">
                    <h5 className="green-color text-up-case">Download</h5>
                    <h1>Free Download Resources</h1>
                  </div>
                  <div className="section-line pos-rel">
                    <img src="/img/shape/section-title-line.png" alt="Section Line" />
                  </div>
                </div> */}
                <div className="download-area">
  {/* Render the dynamic downloads title if it exists */}
                {service.downloadsTitle && (
                  <div className="section-title pos-rel mb-50">
                    <div className="section-text pos-rel">
                      <h5 className="green-color text-up-case">download</h5>
                      <h1>{service.downloadsTitle}</h1>
                    </div>
                    <div className="section-line pos-rel">
                      <img src="/img/shape/section-title-line.png" alt="section-title-line" />
                    </div>
                  </div>
                )}

                {/* Render the downloads list */}
                {service.downloads.map((download, index) => (
                  <a className="download-box mb-20" href={download.link} key={index}>
                    <div className="single-download-inner">
                      <img src={download.icon} alt={download.title} />
                      <span>{download.title}</span>
                      <span className="download-size">{download.size}</span>
                    </div>
                  </a>
                ))}
</div>
              </article>
            </div>

            {/* Right Column with Related Services */}
            <div className="col-xl-5 col-lg-4">
              <div className="service-widget mb-50">
                <div className="widget-title-box mb-30">
                  <h3 className="widget-title">More Services</h3>
                </div>
                <div className="more-service-list ">
                  <ul className='mb-30'>
                    {service.relatedServices && service.relatedServices.map((relatedService) => (
                      <li key={relatedService.id}>
                        <Link to={`/servicesDetails/${relatedService.id}`}>
                          <div className="more-service-icon">
                            <img src={relatedService.icon} alt={relatedService.title} />
                          </div>
                          <div className="more-service-title">{relatedService.title}</div>
                        </Link>
                      </li>
                    ))}
                  </ul>
               <div className="service-widget mb-50">
                  <div className="widget-title-box mb-30">
                     <h3 className="widget-title">Book your appointment</h3>
                  </div>
                  <form className="service-contact-form" action="">
                     <div className="row">
                        <div className="col-xl-12">
                        <div className="contact-input contact-icon contact-user mb-20">
                           <input type="text" placeholder="Enter your name" />
                        </div>
                        </div>
                        <div className="col-xl-12">
                        <div className="contact-input contact-icon contact-mail mb-20">
                           <input type="text" placeholder="Enter your phone" />
                        </div>
                        </div>
                        <div className="col-xl-12">
                        <div className="contact-input contact-icon contact-hourglass">
                           <select className="form-select select_style" aria-label="Default select example">
                              <option defaultValue="Select type of care">Select type of care</option>
                              <option value="1">General Dental Care</option>
                              <option value="2">Cosmetic Dentistry</option>
                              <option value="3">Dental Implants</option>
                              <option value="3">Pediatric Dentistry</option>
                              <option value="3">Dental and Nerve Fillings</option>
                           </select>
                        </div>
                        </div>
                     </div>
                  </form>
               <div className="ser-form-btn text-center mt-40">
                  <a href="#" className="primary_btn btn-icon ml-0" style={{ animationDelay: '0.6s' }} tabIndex="0">
                     <span>+</span>Request for call
                  </a>
               </div>
               </div>

               <div className="service-widget mb-50 border-0 p-0">
               <div className="banner-widget">
                {/* Render the dynamic banner image, link, and alt text */}
                {service.banner && (
                  <Link to={service.banner.link}>
                    <img src={service.banner.image} alt={service.banner.alt} />
                  </Link>
                )}
                </div>

               </div>

                </div>
              </div>
              {/* Additional Forms or Banners Here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesDetailsArea;
