import React from 'react';
import BlogDetailsLeftSide from '../../../components/BlogDetailsLeftSide/BlogDetailsLeftSide';
import BlogDetailsRightSide from '../../../components/BlogDetailsRightSide/BlogDetailsRightSide';

const BlogDetailsArea = ({
  blogDetails,
  popularPosts,
  categories,
  instagramFeeds,
  banner,
  socialProfiles,
}) => {
  return (
    <section className="blog-area pt-120 pb-80">
      <div className="container">
        <div className="row">
          <BlogDetailsLeftSide
            details_topBar={
              <div className="postbox__thumb mb-35">
                <img src={blogDetails.image} alt="blog img" />
              </div>
            }
            title={blogDetails.title}
            content={blogDetails.content}
            date={blogDetails.date}
            author={blogDetails.author.name}
            tags={blogDetails.tags}
          />
          <BlogDetailsRightSide
            authorInfo={blogDetails.author}
            popularPosts={popularPosts}
            categories={categories}
            instagramFeeds={instagramFeeds}
            tags={blogDetails.tags}
            banner={banner}
            socialProfiles={socialProfiles}
          />
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsArea;
