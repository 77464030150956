import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../pages/HomeFour/HomeFourFooter/HomeFourFooter';
import BlogThreeColMasonryArea from './BlogThreeColMasonryArea/BlogThreeColMasonryArea';
import HomeFourFiveNavBar from '../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';



const BlogThreeColMasonry = () => {
    return (
        <>
            <HomeFourFiveNavBar />
            <CommonPageHeader title="Expert Dental Tips" subtitle="Dental Blogs" />
            <BlogThreeColMasonryArea/>
            <Footer/>
        </>
    );
};

export default BlogThreeColMasonry;