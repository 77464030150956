import React from 'react';

const ServicesTwoAbout = () => {
    return (
        <>
            <section className="about-area pt-120 pb-90">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Advanced Dental Care for All Ages</h5>
                                    <h1>Expert Dental Care in Maadi, Cairo – Your Smile, Our Priority</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>At ZA Dental Clinic, we provide a wide range of top-quality dental services tailored to meet your specific needs. Whether you need a routine checkup, cosmetic enhancements, or specialized treatments, our team of experienced dentists in Maadi is here to ensure your oral health is in the best hands. We combine the latest technology with personalized care to help you achieve a healthy, beautiful smile. Book your appointment today and discover why patients trust us for all their dental care needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesTwoAbout;