import React from 'react';
import BlogFirstArticle from '../../Blogs/BlogsArea/BlogFirstArticle';
import BlogFooter from '../../Blogs/BlogsArea/BlogFooter';
import BlogFourthArticle from '../../Blogs/BlogsArea/BlogFourthArticle';
import BlogRightSide from '../../Blogs/BlogsArea/BlogRightSide';
import BlogSecondArticle from '../../Blogs/BlogsArea/BlogSecondArticle';
import BlogThirdArticle from '../../Blogs/BlogsArea/BlogThirdArticle';

const BlogLeftSideBarArea = ({ blogDetails }) => {
   return (
      <section className="blog-area pt-120 pb-80">
         <div className="container">
            <div className="row">
               <BlogRightSide />
               <div className="col-lg-8">
                  {blogDetails ? (
                     <div>
                        <div className="postbox__thumb mb-35">
                           <img src={blogDetails.image} alt="blog img" />
                        </div>
                        <div className="postbox__text mb-40">
                           <h3>{blogDetails.title}</h3>
                           <div>{blogDetails.content}</div>
                        </div>
                     </div>
                  ) : (
                     <>
                        <BlogFirstArticle />
                        <BlogSecondArticle />
                        <BlogThirdArticle />
                        <BlogFourthArticle />
                        <BlogFooter />
                     </>
                  )}
               </div>
            </div>
         </div>
      </section>
   );
};

export default BlogLeftSideBarArea;
