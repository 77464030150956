import React from 'react';
import { Link } from 'react-router-dom';

const HomeThreeHiring = ({ btn_text, h4hireText, title = 'About Us', pinkColor}) => {
    return (
        <>
            <div className={`hire-text hire-text-2 ${h4hireText && h4hireText}`}>
                <div className="about-title mb-20">
                    <h5 className={`${pinkColor && pinkColor}`}>{title}</h5>
                    <h1>Committed to Excellence in Dental Care</h1>
                </div>
                <div className="about-text">
                    <p>At ZA Dental Clinic, we prioritize your smile and well-being with our comprehensive dental services. Our experienced team uses the latest technology to deliver top-quality care in a comfortable and welcoming environment.</p>
                </div>
                <ul className="professinals-list pt-10 pb-20">
                    <li>
                        <i className="fa fa-check"></i>
                        <b>Personalized Care: </b>Each treatment plan is crafted to meet your unique dental needs and health goals.
                    </li>
                    <li>
                        <i className="fa fa-check"></i>
                        <b>Advanced Technology:</b> Utilizing state-of-the-art equipment for accurate diagnoses and effective treatments.
                    </li>
                    <li>
                        <i className="fa fa-check"></i>
                        <b>Expert Team:</b> Our dedicated professionals are committed to continuous education and exceptional patient care.
                    </li>
                    <li>
                        <i className="fa fa-check"></i>
                        <b>Trusted Techniques:</b> From preventive care to cosmetic dentistry, we employ trusted methods for lasting results.
                    </li>
                </ul>
                <div className="hiring-button">
                    <Link to="/contact"
                        className="primary_btn btn-icon btn-icon-green ml-0"><span>+</span>{btn_text}</Link>
                </div>
            </div>
        </>
    );
};

export default HomeThreeHiring;