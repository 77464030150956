import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../pages/HomeFour/HomeFourFooter/HomeFourFooter';
import HomeFourFiveNavBar from '../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';
import ContactArea from './ContactArea/ContactArea';
import ContactFormArea from './ContactFormArea/ContactFormArea';
import ContactMap from './ContactMap/ContactMap';

const Contact = () => {
    return (
        <>
            <HomeFourFiveNavBar />
            <CommonPageHeader title="Contact Us" subtitle="Contact" />
            <ContactArea/>
            <ContactFormArea/>
            <ContactMap/>
            <Footer/>
        </>
    );
};

export default Contact;