
const portfolioData = [
    {
        id: 1,
        img: 'img/portfolio/port1.jpg',
        category: 'Cosmetic dentistry',
    },

    {
        id: 2,
        img: 'img/portfolio/port2.jpg',
        category: 'Dental Implants',
    },
    {
        id: 3,
        img: 'img/portfolio/port3.jpg',
        category: 'Pediatric Dentistry',
    },
    {
        id: 4,
        img: 'img/portfolio/port6.jpg',
        category: 'Orthodontics',
    },
    {
        id: 5,
        img: 'img/portfolio/port10.jpg',
        category: 'The Clinic',
    },
    {
        id: 6,
        img: 'img/portfolio/port11.jpg',
        category: 'The Clinic',
    },
    {
        id: 7,
        img: 'img/portfolio/port4.jpg',
        category: 'Root canal',
    },
    {
        id: 8,
        img: 'img/portfolio/port8.jpg',
        category: 'Pediatric Dentistry',
    },
    {
        id: 9,
        img: 'img/portfolio/port9.jpg',
        category: 'Pediatric Dentistry',
    },
    {
        id: 10,
        img: 'img/portfolio/port101.jpg',
        category: 'Root canal',
    },
    {
        id: 11,
        img: 'img/portfolio/port111.jpg',
        category: 'The Clinic',
    },
    {
        id: 12,
        img: 'img/portfolio/port12.jpg',
        category: 'Cosmetic dentistry',
    },
]
export default portfolioData;