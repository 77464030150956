import React from 'react';
import { Link } from 'react-router-dom';

const HomeFourSingleHeroSlide = () => {
    return (
        <>
            <div className="col-xl-8 col-lg-12 col-md-10">
                <div className="hero-text">
                    <div className="hero-slider-caption h4hero-content mb-35">
                        <h1 className="white-color">Maadi Dental Clinic</h1>
                        <p className="white-color">Looking for a dentist in Maadi who prioritizes your oral health? At ZA Dental Clinic, we offer a comprehensive range of dental services to meet your needs, from routine cleanings to advanced procedures.</p>

                        <div className="h4-span">
                            <span className="white-color"><i className="fal fa-arrow-circle-right"></i><span>We believe in making dental care accessible to everyone</span></span>
                            <span className="white-color"><i className="fal fa-arrow-circle-right"></i><span>We deliver the highest quality dental care while ensuring your comfort.</span></span>
                        </div>

                    </div>
                    <div className="hero-slider-btn h4hero-btn">
                        <Link to="/doctorsTwo" className="primary_btn btn-icon ms-0"><span>+</span>learn more</Link>
                        <Link to="/servicesTwo" className="primary_btn btn-icon btn-icon-green ms-0"><span>+</span>Our services</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeFourSingleHeroSlide;