import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/Shared/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';
import HomeFourFiveNavBar from '../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';


// Define your blog posts data
const blogPosts = {
  "1": {
    title: "soso",
    date: "September 15, 2021",
    content: [
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
      },
      {
        type: 'paragraph',
        text: 'Bccaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum...',
      },
      {
        type: 'blockquote',
        text: 'This health blog from NPR takes a fairly broad look at the medical world.',
        footer: 'Rosalina Ponggggg',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
      {
        type: 'image',
        src: 'img/blog/b6.jpg',
        alt: 'blog img',
      },
      {
        type: 'heading',
        level: 4,
        text: 'A cleansing hot shower or bath',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
      {
        type: 'heading',
        level: 4,
        text: 'Setting the mood with incense',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
    ],
    image: 'img/blog/details/featured-image.jpg',
    tags: ['organic', 'Foods', 'tasty'],
    author: {
      name: "Dr. Fatma",
      image: "img/blog/details/author1.png",
      bio: "Dr. Fatma is a leading dental specialist with over 15 years of experience...",
      socialProfiles: [
        { url: "https://facebook.com/author1", iconClass: "fab fa-facebook-f" },
        { url: "https://twitter.com/author1", iconClass: "fab fa-twitter" },
      ],
    },
  },
  "2": {
    title: "soso",
    date: "September 15, 2021",
    content: [
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
      },
      {
        type: 'paragraph',
        text: 'Bccaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum...',
      },
      {
        type: 'blockquote',
        text: 'This health blog from NPR takes a fairly broad look at the medical world.',
        footer: 'Rosalina Ponggggg',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
      {
        type: 'image',
        src: 'img/blog/b6.jpg',
        alt: 'blog img',
      },
      {
        type: 'heading',
        level: 4,
        text: 'A cleansing hot shower or bath',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
      {
        type: 'heading',
        level: 4,
        text: 'Setting the mood with incense',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
    ],
    image: 'img/blog/details/featured-image.jpg',
    tags: ['organic', 'Foods', 'tasty'],
    author: {
      name: "Dr. Fatma",
      image: "img/blog/details/author1.png",
      bio: "Dr. Fatma is a leading dental specialist with over 15 years of experience...",
      socialProfiles: [
        { url: "https://facebook.com/author1", iconClass: "fab fa-facebook-f" },
        { url: "https://twitter.com/author1", iconClass: "fab fa-twitter" },
      ],
    },
  },

  "3": {
    title: "sososss",
    date: "September 15, 2021",
    content: [
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
      },
      {
        type: 'paragraph',
        text: 'Bccaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum...',
      },
      {
        type: 'blockquote',
        text: 'This health blog from NPR takes a fairly broad look at the medical world.',
        footer: 'Rosalina Ponggggg',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
      {
        type: 'image',
        src: 'img/blog/b6.jpg',
        alt: 'blog img',
      },
      {
        type: 'heading',
        level: 4,
        text: 'A cleansing hot shower or bath',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
      {
        type: 'heading',
        level: 4,
        text: 'Setting the mood with incense',
      },
      {
        type: 'paragraph',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      },
    ],
    image: 'img/blog/details/featured-image.jpg',
    tags: ['organic', 'Foods', 'tasty'],
    author: {
      name: "Dr. Fatma",
      image: "img/blog/details/author1.png",
      bio: "Dr. Fatma is a leading dental specialist with over 15 years of experience...",
      socialProfiles: [
        { url: "https://facebook.com/author1", iconClass: "fab fa-facebook-f" },
        { url: "https://twitter.com/author1", iconClass: "fab fa-twitter" },
      ],
    },
  },
  // Add other blog posts as needed
};

// Convert blogPosts object to an array
const blogPostsArray = Object.entries(blogPosts).map(([id, post]) => ({ id, ...post }));

const BlogDetails = () => {
  const { id } = useParams(); // Get the current blog ID from the URL
  const blogDetails = blogPosts[id]; // Get the current blog post

  // Handle invalid blog IDs
  if (!blogDetails) {
    return (
      <>
        <HomeHeader />
        <CommonPageHeader title="Blog Details" subtitle="Details" />
        <p>Blog post not found.</p>
        <Footer />
      </>
    );
  }

  // Find the index of the current blog post
  const currentIndex = blogPostsArray.findIndex((post) => post.id === id);

  // Get the previous and next blog posts
  const previousPost = blogPostsArray[currentIndex - 1] || null;
  const nextPost = blogPostsArray[currentIndex + 1] || null;

  // Additional data for the sidebar
  const instagramFeeds = [
    { image: "img/instagram/ins1.jpg", url: "#" },
    { image: "img/instagram/ins2.jpg", url: "#" },
  ];
  const banner = { image: "img/blog/details/banner.png", link: "/contact" };
  const socialProfiles = [
    { url: "https://facebook.com", icon: "fab fa-facebook-f" },
    { url: "https://twitter.com", icon: "fab fa-twitter" },
  ];

  return (
    <>
      <HomeFourFiveNavBar />
      <CommonPageHeader title="Blog Details" subtitle="Details" />
      <BlogDetailsArea
        blogDetails={blogDetails}
        previousPost={previousPost} // Pass the previous post
        nextPost={nextPost} // Pass the next post
        instagramFeeds={instagramFeeds}
        banner={banner}
        socialProfiles={socialProfiles}
      />
      <Footer />
    </>
  );
};

export default BlogDetails;
