import React from 'react';
import { Link } from 'react-router-dom';


const CalculateArea = () => {

   return (
      <>
         <section className="calculate-area pos-rel pt-115 pb-120" data-background="img/calculate/calculate-bg.png">
            <div className="container calculate_wrapper">
               <div className="row">
                  <div className="col-xl-7 col-lg-6 col-md-10">
                     <div className="section-title calculate-section pos-rel mb-45">
                        <div className="section-text section-text-white pos-rel">
                           <h5>Dentist near you</h5>
                           <h1 className="white-color">Find the Best Dental Care in Maadi.</h1>
                           <p>Choose from services like dental fillings, braces, dental implants, and more. Book an appointment today to experience the best dental care in Maadi.</p>
                        </div>
                     </div>
                     <div className="section-button section-button-left mb-30">
                        <Link to="/contact"
                         className="primary_btn btn-icon btn-icon-green ml-0"><span>+</span>Make Appointment</Link>
                     </div>
                  </div>
                  <div className="col-xl-5 col-lg-6">
                     <div className="calculate-box white-bg">
                        <div className="calculate-content">
                           <div className="row">
                              <div className="col-xl-12">
                                 <select defaultValue={'DEFAULT'} className="form-select select_style">
                                    <option defaultValue={'When would you like our support?'}>
                                     Select Service Type</option>
                                    <option defaultValue={'DEFAULT'}>Teeth Whitening</option>
                                    <option defaultValue={'DEFAULT'}>Dental Fillings</option>
                                    <option defaultValue={'DEFAULT'}>Pediatric Dentistry</option>
                                    <option defaultValue={'DEFAULT'}>Braces</option>
                                    <option defaultValue={'DEFAULT'}>Implants</option>
                                 </select>
                              </div>
                              <div className="col-xl-12">

                                 <select defaultValue={'DEFAULT'} className="form-select select_style">
                                    <option defaultValue={'When would you like us arrive?'}>Select Time Frame</option>
                                    <option defaultValue={'DEFAULT'}>As Soon As Possible</option>
                                    <option defaultValue={'DEFAULT'}>This Week</option>
                                    <option defaultValue={'DEFAULT'}>Next Week</option>


                                 </select>
                              </div>
                              <div className="col-xl-12">
                                 <select defaultValue={'DEFAULT'} className="form-select select_style">
                                    <option defaultValue={'How long should we stay?'}>Select Your Payment Method</option>
                                    <option defaultValue={'DEFAULT'}>Cash</option>
                                    <option defaultValue={'DEFAULT'}>Credit/Debit Card</option>
                                    <option defaultValue={'DEFAULT'}>Installment Plan</option>
                                    <option defaultValue={'DEFAULT'}>Not Sure</option>
                                 </select>
                              </div>
                              <div className="col-xl-12">
                                 <select defaultValue={'DEFAULT'} className="form-select select_style">
                                    <option defaultValue={'Where are you located?'}>Where are you located?</option>
                                    <option defaultValue={'DEFAULT'}>Maadi</option>
                                    <option defaultValue={'DEFAULT'}>Zahra Al-Maadi</option>
                                    <option defaultValue={'DEFAULT'}>Nasr City</option>
                                    <option defaultValue={'DEFAULT'}>The 5th Settlement</option>
                                    <option defaultValue={'DEFAULT'}>15 May city</option>
                                 </select>
                              </div>
                              <div className="col-xl-12">
                                 <form className="calculate-form" action="#">
                                    <input type="text" placeholder="Your Phone number"/>
                                       <i className="fas fa-phone"></i>
                                 </form>
                              </div>
                           </div>
                        </div>
                        <a href="#" className="primary_btn mt-40">submit query</a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CalculateArea;