import React from 'react';
import HomeFourSingleBlog from '../../../components/HomeFourSingleBlog/HomeFourSingleBlog';

const HomeFourBlogs = () => {
   return (
      <>
         <section className="latest-news-area pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                     <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h5>Dental Blogs</h5>
                           <h1>Get Every Single Dental Tips.</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <HomeFourSingleBlog
                     title="Shining Smile Starts Here: Your Guide to Dental Care in Maadi"
                     date="5 Jan 2024"
                     summary="Looking for a dentist in Maadi? This guide covers everything you need..."
                     name="Dr. Fatma"
                     bgImg="img/home4/news/blog__thumb1.png"
                     path="/blogDetails/1"/>
                     
                  <HomeFourSingleBlog
                     title="Healthy Teeth, Happy You: Essential Dental Services at ZA Dntal Clinic"
                     date=" 15 Feb 2023"
                     summary = "Discover how essential dental services from ZA Dental Clinic can keep your smile..."
                     name="Dr. Ahmed"
                     bgImg="img/home4/news/blog__thumb2.png"
                     path="/blogDetails/2"/>
                  <HomeFourSingleBlog
                     title="Stop Gum Disease in Its Tracks: Early Signs & Preventive Care Tips"
                     date="24 Feb 2023"
                     summary="Don't let gum disease steal your smile! Learn the early warning signs..." 
                     name="Dr. Adnan"
                     bgImg="img/home4/news/blog__thumb3.png"
                     path="/blogDetails/3"/>

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFourBlogs;