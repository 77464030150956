import React from 'react';
import { Link } from 'react-router-dom';
import HomeSingleFooterBlog from '../../../components/HomeSingleFooterBlog/HomeSingleFooterBlog';

const HomeFourFooter = () => {
   return (
      <>
         <footer>
            <div className="footer-top h4_footer primary-bg footer-map pos-rel pt-120 pb-80">
               <div className="container">
                  <div className="row justify-content-between">
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="footer-contact-info footer-contact-info-3 mb-40">
                           <div className="footer-logo mb-35">
                              <Link to="/"><img src="/img/logo/footer-logo-3.png" alt=""/></Link>
                           </div>
                           <div className="footer-contact-content mb-25">
                              <p className="p-0">Looking for a dentist in Maadi who prioritizes your oral health? At ZA Dental Clinic, we offer a comprehensive range of dental services to meet your needs, from routine cleanings to advanced procedures.</p>
                           </div>
                           <div className="footer-emailing">
                              <ul>
                                 <li><i className="far fa-envelope"></i>zadentalclinic@gmail.com</li>
                                 <li><i className="far fa-clone"></i>+20 (111) 6111 261</li>
                                 <li><i className="far fa-flag"></i>22 Omar Hila street, Maadi</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="footer-widget h4footer-widget mb-40">
                           <div className="footer-title">
                              <h3>More Links</h3>
                           </div>
                           <div className="footer-menu footer-menu-2 h4footer-menu">
                              <ul>
                                 <li><Link to="/doctorsTwo">About</Link></li>
                                 <li><Link to="/servicesTwo">Services</Link></li>
                                 <li><Link to="/portfolioThreeColumn">Gallery</Link></li>
                                 <li><Link to="/blogThreeColMasonry">Blogs</Link></li>
                                 {/* <li><Link to="/servicesDetails">Dermatology</Link></li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="footer-widget h4footer-widget mb-40">
                           <div className="footer-title">
                              <h3>Recent News</h3>
                              <HomeSingleFooterBlog
                                 title="New Technology Transforms Dentistry at ZA Dental Clinic"
                                 Date="05 Jan 2024" />
                              <HomeSingleFooterBlog
                                 title="ZA Dental Clinic teams up with a local preschool to promote healthy oral hygiene habits"
                                 Date= "05 Jan 2024"/>
                           </div>
                           {/* <div className="blog-feeds pe-15">
                               <HomeSingleFooterBlog image="1" />
                               <HomeSingleFooterBlog image="2" />
                               <HomeSingleFooterBlog image="3" />
                           </div> */}
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="footer-widget h4footer-widget mb-40">
                           <div className="footer-title">
                              <h3>Opening Hours</h3>
                           </div>
                           <div className="h4events-list mb-30">
                              <ul>
                                 <li><i className="fal fa-clock"></i><span>Sat - Thursday<span>06:00 pm - 10:00 pm</span></span></li>
                                 <li><i className="fal fa-times-square"></i><span className="close-days">Friday Closed</span></li>
                              </ul>
                           </div>
                           <div className="h4footer-social">
                              <ul className="list-inline">
                                 <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                 <li><a href="#"><i className="fab fa-google"></i></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="footer-bottom pt-25 pb-20">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="footer-copyright footer-copyright-3 text-center">
                           <a href="https://www.ngb-eg.com" target="_blank">Copyright by@ Next Generation Business</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default HomeFourFooter;