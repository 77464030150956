import React from 'react';
import HomeFourSingleTestimonial from '../../../components/HomeFourSingleTestimonial/HomeFourSingleTestimonial';

const HomeFourTestimonials = () => {
   return (
      <>
         <div className="testimonials pt-150 pb-80 h4_testimonials" data-background="img/home4/bg/testimonials-bg.jpg">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 offset-xl-5 h4testi-col">
                     <div className="row">

                        <HomeFourSingleTestimonial
                           image="1"
                           name='osama eltaher'
                           testi="عيادة ممتازة جدا من حيث النظافة و المواعيد و طبعا  الاطباء شباب ممتازين و صابورين جدا

" />
                        <HomeFourSingleTestimonial
                           image="2"
                           name='Duha Elakram'
                           testi="تنظيم المواعيد بجد هايل .. التعامل مع الأطفال بطريقة friendly جداً .. محستش بألم الحمدلله
"/>
                        <HomeFourSingleTestimonial
                           image="3"
                           name='Hala Abo El Seoud'
                           testi="افضل عيادة أسنان على الإطلاق💥💯٪
*تنظيم المواعيد بشكل عظيم لان معروف ان اللحظات دى بتكون صعبة والانتظار مش حلو حقيقى مفيش مرة رحت وانتظرت فى العيادة وده لان المواعيد منظمة جدا بحيث يكون الوقت ليك لوحدك
 *تعقيم وجودة عالية فى الأدوات والاجهزة
*سرعة ومهارة فائقة فى البنج والخلع والحشو 
خبرة عالية فى كل مايخص الأسنان❤️❤️❤️
عن تجربة فى الخلع والحشو بدون ألم نهائى
بالتوفيق يارب ♥️♥️"/>
                        <HomeFourSingleTestimonial
                           image="1"
                           name='Rabab Zain'
                        testi="دكتور ممتاز وشغل باتقان وبدون الم
ربنا يباركله ويحفظه"/>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeFourTestimonials;