import React from 'react';
import { Link } from 'react-router-dom';

const HomeFourSingleBlog = ({title, date, summary, name, bgImg, path}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="h4latestnews-box pos-rel fix mb-30">
                    <div className="h4latestnews-bg"><img src={bgImg} alt=""/></div>
                    <div className="h4latestnews-wrapper pos-rel">
                        <div className="h4news-content">
                            <h4 className="theme-color f-600">
                                <Link to={path}>{title}</Link>
                            </h4>
                            <p>{summary}</p>
                        </div>
                        <div className="h4news-admin d-flex align-items-center mb-40">
                            <div className="h4adminnews-thumb">
                                <span><img src="img/home4/news/news-admin1.png" alt=""/>
                                    <span className="theme-color f-600">{name}</span></span>
                            </div>
                            <div className="h4adminnews-date">
                                <span><i className="far fa-calendar-alt"></i>{date}</span>
                            </div>
                        </div>
                        <div className="h4news-button">
                            <Link to={path} className="primary_btn btn-icon btn-icon-gray ml-0"><span>+</span>read more</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeFourSingleBlog;
