import React from 'react';
import { Link } from 'react-router-dom';

const BlogDetailsRightSide = ({
  authorInfo,
  popularPosts,
  categories,
  instagramFeeds,
  tags,
  banner,
  socialProfiles,
}) => {
  return (
    <div className="col-lg-4">
      {/* Search Objects */}
      {/* <div className="widget mb-40">
        <div className="widget-title-box mb-30">
          <span className="animate-border"></span>
          <h3 className="widget-title">Search Objects</h3>
        </div>
        <form className="search-form">
          <input type="text" placeholder="Search your keyword..." />
          <button type="submit">
            <i className="fas fa-search"></i>
          </button>
        </form>
      </div> */}

      {/* About Me */}
      {authorInfo && (
        <div className="widget mb-40">
          <div className="widget-title-box mb-30">
            <span className="animate-border"></span>
            <h3 className="widget-title">About Me</h3>
          </div>
          <div className="about-me text-center">
            <img src={authorInfo.image} alt={authorInfo.name} />
            <h4>{authorInfo.name}</h4>
            <p>{authorInfo.bio}</p>
            <div className="widget-social-icon">
              {authorInfo.socialProfiles.map((profile, index) => (
                <a key={index} href={profile.url}>
                  <i className={profile.icon}></i>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Popular Feeds */}
      {popularPosts && (
        <div className="widget mb-40">
          <div className="widget-title-box mb-30">
            <span className="animate-border"></span>
            <h3 className="widget-title">Popular Feeds</h3>
          </div>
          <ul className="recent-posts">
            {popularPosts.map((post) => (
              <li key={post.id}>
                <div className="widget-posts-image">
                  <Link to={`/blogDetails/${post.id}`}>
                    <img src={post.image} alt={post.title} />
                  </Link>
                </div>
                <div className="widget-posts-body">
                  <h6 className="widget-posts-title">
                    <Link to={`/blogDetails/${post.id}`}>{post.title}</Link>
                  </h6>
                  <div className="widget-posts-meta">{post.date}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Categories */}
      {categories && (
        <div className="widget mb-40">
          <div className="widget-title-box mb-30">
            <span className="animate-border"></span>
            <h3 className="widget-title">Categories</h3>
          </div>
          <ul className="cat">
            {categories.map((category, index) => (
              <li key={index}>
                <Link to={`/blogs/category/${category.name}`}>
                  {category.name} <span className="f-right">{category.count}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Social Profile */}
      {socialProfiles && (
        <div className="widget mb-40">
          <div className="widget-title-box mb-30">
            <span className="animate-border"></span>
            <h3 className="widget-title">Social Profile</h3>
          </div>
          <div className="social-profile">
            {socialProfiles.map((profile, index) => (
              <a key={index} href={profile.url}>
                <i className={profile.icon}></i>
              </a>
            ))}
          </div>
        </div>
      )}

      {/* Instagram Feeds */}
      {instagramFeeds && (
        <div className="widget mb-40">
          <div className="widget-title-box mb-30">
            <span className="animate-border"></span>
            <h3 className="widget-title">Instagram Feeds</h3>
          </div>
          <ul id="Instafeed">
            {instagramFeeds.map((feed, index) => (
              <li key={index}>
                <a href={feed.url}>
                  <img src={feed.image} alt={`Instagram feed ${index + 1}`} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Tags */}
      {/* {tags && (
        <div className="widget mb-40">
          <div className="widget-title-box mb-30">
            <span className="animate-border"></span>
            <h3 className="widget-title">Tags</h3>
          </div>
          <div className="tag">
            {tags.map((tag, index) => (
              <Link key={index} to={`/blogs/tag/${tag}`}>
                {tag}
              </Link>
            ))}
          </div>
        </div>
      )} */}

      {/* Banner */}
      {banner && (
        <div className="widget mb-40 p-0 b-0">
          <div className="banner-widget">
            <Link to={banner.link}>
              <img src={banner.image} alt="Banner" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogDetailsRightSide;
