import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../../pages/HomeFour/HomeFourFooter/HomeFourFooter';
import HomeFact from '../../Home/Home/HomeFact/HomeFact';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import CounterArea from '../DoctorsOne/CounterArea/CounterArea';
import DoctorsTwoTeamArea from './DoctorsTwoTeamArea/DoctorsTwoTeamArea';
import HomeFourFiveNavBar from '../../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';


const DoctorsTwo = () => {
    return (
        <>
            
            <HomeFourFiveNavBar />
            <CommonPageHeader title="Meet Our Expert Dental Team in Maadi" subtitle="Doctor" />
            <DoctorsTwoTeamArea/>
            <HomeFact />
            <CounterArea/>
            <Footer/>
        </>
    );
};

export default DoctorsTwo;