import React from 'react';

const HomeFourAbout = () => {
   return (
      <>
         <section className="about-area pt-130 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     <div className="h4about-thumb pos-rel">
                        <img src="img/home4/about/about_thumb1.jpg" alt="" />
                        <a href="#" className="call-btn f-700 white-color green-bg">
                           <i className="call-icon"><img src="img/home4/icon/about__phone__icon.png" alt="" />
                           </i><span>Contact Us : 011 (161) 11261</span></a> 
                        <img src="img/home4/about/about_thumb2.jpg" alt="" className="about-overlap__thumb" /> </div>
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side h4about-right mb-30">
                        <div className="about-title mb-20">
                           <h5 className="pink-color">Maadi Dentist</h5>
                           <h1>Exceptional Dental Care for 12 Years</h1>
                        </div>
                        <div className="about-text">
                           <p className="theme-color">At ZA Dental Clinic, we are passionate about providing high-quality dental care to patients in Maadi and the surrounding areas. For over 12 years, we have been committed to helping our patients achieve and maintain healthy, beautiful smiles.</p>
                           <p className="mb-20">From preventive dental check-ups to cosmetic dentistry and orthodontics, we offer a full range of services to keep your smile bright and healthy.</p>
                        </div>
                        <div className="about-author d-flex align-items-center">
                           <div className="author-ava h4author-ava">
                              <img src="img/home4/about/ava.png" alt="" />
                           </div>
                           <div className="author-desination h4author-destination">
                              <p>We offer guidance on dental health practices to empower you to make informed decisions about your dental care</p>
                              <h4 className="mb-0">Dr.Ahmed Ibrahim</h4>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFourAbout;