import React from 'react';
import Masonry from 'react-masonry-css';
import BlogLgSingleColumn from '../../../components/BlogLgSingleColumn/BlogLgSingleColumn';
import BlogPaginationActiveTwo from '../../../components/blogPaginationActiveTwo/BlogPaginationActiveTwo';
import BlogSmSingleColumn from '../../../components/BlogTwoSingleColumn/BlogSmSingleColumn';

const BlogThreeColMasonryArea = () => {
    const breakpointColumns = {
        default: 3,
        1100: 2,
        768: 1,
    };

    const blogPosts = [
        {
            id: 1,
            image: '1',
            title: 'How Professional Teeth Cleaning Can Prevent Cavities and Gum Disease',
            description: 'Learn why regular teeth cleanings at the dentist are crucial in preventing tooth decay, gum disease, and plaque buildup for long-term oral health.'
        },
        {
            id: 2,
            image: '2',
            title: 'Teeth Whitening: Best Options for a Brighter Smile',
            description: 'Discover the most effective teeth whitening treatments to get rid of stains and brighten your smile, including in-office and at-home options.'
        },
        {
            id: 3,
            image: '3',
            title: 'Dental Crowns vs Veneers: Which is Better for Damaged Teeth?',
            description: 'Compare dental crowns and veneers to find out which option is the best choice for restoring damaged or cracked teeth while enhancing your smile.'
        },

        {
            id: 4,
            image: '4',
            title: 'Dental Implants: The Permanent Solution for Missing Teeth',
            description: 'Find out why dental implants are considered the most reliable long-term solution for replacing missing teeth and restoring chewing function and aesthetics.'
        },
        {
            id: 5,
            image: '5',
            title: 'Pediatric Dental Care: How to Prevent Cavities in Children',
            description: 'Learn how early dental care and regular visits to a pediatric dentist can prevent cavities and ensure your childs teeth stay healthy.'},
        {
            id: 6,
            image: '6',
            title: 'Root Canal Treatment: How to Save Your Infected Tooth',
            description: 'Discover how root canal therapy can effectively remove infection, relieve pain, and preserve your natural tooth, preventing extraction.'
        },
        // Add more blog posts as needed
    ];

    return (
        <>
            <section className="blog-area pt-120 pb-120">
                <div className="container">
                    <Masonry
                        breakpointCols={breakpointColumns}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {blogPosts.map((post, index) => (
                            <BlogLgSingleColumn
                                key={index}
                                image={post.image}
                                title={post.title}
                                description={post.description}
                                masonry={true}
                            />
                        ))}
                    </Masonry>

                    <BlogPaginationActiveTwo />
                </div>
            </section>
        </>
    );
};

export default BlogThreeColMasonryArea;
