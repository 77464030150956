import React from 'react';
import { useParams } from 'react-router-dom'; // Get service ID from URL
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
// import Footer from '../../../components/Shared/Footer';
import Footer from '../../../pages/HomeFour/HomeFourFooter/HomeFourFooter';
import ServicesDetailsArea from './ServicesDetailsArea/ServicesDetailsArea';
import HomeFourFiveNavBar from '../../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';


// Sample services data
const servicesData = [
  {
    id: 1, // Unique ID for the service
    title: "Cosmetic Dentistry in Maadi",
    mainImage: "/img/services/service-thumb-1.png",
    description: "We are here to help when you need us.",
    paragraphs: [
      "If you're looking to achieve a radiant, confident smile, our cosmetic dentistry services in Maadi, offer a variety of advanced treatments tailored to your needs. Whether it's teeth whitening, veneers, or a complete smile makeover, we are dedicated to enhancing the appearance of your teeth and helping you achieve your dream smile. This guide will walk you through the different cosmetic dental procedures we provide, their benefits, and how they can improve both the aesthetics and functionality of your teeth.",

      "Cosmetic Dentistry focuses on improving the appearance of your teeth, gums, and overall smile. It involves a range of procedures that address tooth discoloration, chipped or misaligned teeth, and gummy smiles. Cosmetic treatments not only enhance the look of your smile but also boost self-confidence. Our Maadi dental clinic specializes in the following cosmetic procedures:",

      "Teeth whitening is one of the most popular cosmetic dental treatments. We use advanced techniques to safely remove stains caused by coffee, tea, smoking, or aging, resulting in a visibly brighter smile. Our clinic offers both in-office teeth whitening and take-home whitening kits to suit your convenience.",
    ],

    banner: {
    image: "/img/services/services-banner.png",
    link: "/contact",
    alt: "Service Banner"
  },

   headings: [
  {
    type: "h2",
    text: "Introduction",
    content: [
      { type: "paragraph", text: "If you're looking to achieve a radiant, confident smile, our cosmetic dentistry services in Maadi, Cairo offer a variety of advanced treatments tailored to your needs. Whether it's teeth whitening, veneers, or a complete smile makeover, we are dedicated to enhancing the appearance of your teeth and helping you achieve your dream smile. This guide will walk you through the different cosmetic dental procedures we provide, their benefits, and how they can improve both the aesthetics and functionality of your teeth." },
      { type: "h3", text: "" },
      { type: "paragraph", text: "" },
    ]
  },
  {
    type: "h2",
    text: "What is Cosmetic Dentistry?",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Cosmetic Dentistry focuses on improving the appearance of your teeth, gums, and overall smile. It involves a range of procedures that address tooth discoloration, chipped or misaligned teeth, and gummy smiles. Cosmetic treatments not only enhance the look of your smile but also boost self-confidence. Our Maadi dental clinic specializes in the following cosmetic procedures:" },
    ]
  },
  {
    type: "h2",
    text: "Popular Cosmetic Dentistry Procedures at Our Maadi Clinic",
    content: [
      { type: "h3", text: "Teeth Whitening" },
      { type: "paragraph", text: "Teeth whitening is one of the most popular cosmetic dental treatments. We use advanced techniques to safely remove stains caused by coffee, tea, smoking, or aging, resulting in a visibly brighter smile. Our clinic offers both in-office teeth whitening and take-home whitening kits to suit your convenience." },
    ]
  },
  {
    type: "h2",
    text: "",
    content: [
      { type: "h3", text: "Porcelain Veneers" },
      { type: "paragraph", text: "Porcelain veneers are thin, custom-made shells that cover the front surface of your teeth, correcting imperfections like chipped teeth, gaps, and discoloration. Our team in Maadi meticulously designs and places veneers to create a Hollywood smile that looks natural and flawless." },
    ]
  },
  // {
  //   type: "h2",
  //   text: "",
  //   content: [
  //     { type: "h3", text: "Dental Bonding" },
  //     { type: "paragraph", text: "Dental bonding involves applying a tooth-colored resin to fix minor issues like chipped teeth or small gaps. It’s a cost-effective alternative to veneers and provides immediate results. The bonding material is sculpted and polished to blend seamlessly with your natural teeth, offering a quick yet durable fix." },
  //   ]
  // },
  {
    type: "h2",
    text: "",
    content: [
      { type: "h3", text: "Smile Makeovers" },
      { type: "paragraph", text: "A smile makeover combines multiple cosmetic dental procedures to completely transform the appearance of your smile. It may include veneers, crowns, teeth whitening, and even gum contouring depending on your unique needs. Our cosmetic dentist will work closely with you to develop a customized treatment plan that aligns with your aesthetic goals." },
    ]
  },
  {
    type: "h2",
    text: "The Benefits of Cosmetic Dentistry",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Cosmetic dentistry offers numerous benefits beyond aesthetics. These procedures can improve your oral health, help with proper bite alignment, and protect teeth from future damage." },
    
    ]
  },
  {
    type: "h2",
    text: "How to Choose the Right Cosmetic Dental Procedure",
    content: [
    
      { type: "h3", text: "" },
      { type: "paragraph", text: "Selecting the best cosmetic dental treatment depends on your individual needs and goals. Here are some factors to consider:" },
      {
        type: "list",
        listItems: [
          "- Current Condition of Your Teeth",
          "- Desired Outcome",
          "- Budget"
        ]
      },
    ]
  },
  {
    type: "h2",
    text: "Why Choose Our Dental Clinic for Cosmetic Dentistry?",
    content: [
    
      { type: "h3", text: "" },
      { type: "paragraph", text: "Our clinic in Maadi, Cairo is equipped with the latest dental technology and a team of highly skilled cosmetic dentists dedicated to providing exceptional results. We focus on delivering pain-free and minimally invasive treatments, ensuring every visit is comfortable and rewarding. Additionally, we offer flexible payment plans and consultation options to make your smile goals more accessible." },
    ]
  },
  {
    type: "h2",
    text: "FAQs About Cosmetic Dentistry",
    content: [
    
      { type: "h3", text: "Q1: How long do porcelain veneers last?" },
      { type: "paragraph", text: "A- With proper care, porcelain veneers can last 10-15 years. They are designed to be stain-resistant and highly durable." },
      { type: "h3", text: "Q2: Is teeth whitening safe?" },
      { type: "paragraph", text: "A: Yes, professional teeth whitening is safe and effective when done by a qualified dentist. We use safe concentrations of bleaching agents to achieve the best results without damaging the enamel." },
      { type: "h3", text: "Q3: Can I get cosmetic dentistry if I have existing dental issues?" },
      { type: "paragraph", text: "A: Yes, but any underlying issues like cavities or gum disease must be treated first. Our team will conduct a thorough evaluation to create a personalized treatment plan." },
    ]
  },
  {
    type: "h2",
    text: "Schedule Your Cosmetic Dentistry Consultation in Maadi",
    content: [
      { type: "paragraph", text: "Ready to transform your smile? Contact our Maadi dental clinic today to schedule a cosmetic dentistry consultation. Our friendly team is here to answer your questions and help you choose the best treatment to achieve your dream smile." },
      {
          type: "paragraph",
          parts: [
            { text: "Check out our " },
            { link: { href: "/portfolioThreeColumn", text: "Dentistry Services" } },
            { text: " or maintaining a healthy smile." }
          ]
      },
      {
          type: "paragraph",
          parts: [
            { text: "Learn more about cosmetic dental trends at the " },
            { link: { href: "https://aacd.com/", text: "American Academy of Cosmetic Dentistry" } },
          ]
        },
    ]
  },
],

    features: [
      {
        icon: "/img/services/ser-fea-icon-1.png",
        title: "Advanced Cosmetic Techniques",
        items: [
          "We use the latest digital smile design technology to create custom treatment plans that visualize your results before the procedure.",
          "Our clinic is equipped with state-of-the-art dental tools, ensuring precision and minimally invasive treatments for all cosmetic procedures.",
          "We offer a range of high-quality materials for veneers, crowns, and dental bonding, providing long-lasting and natural-looking results.",
        ]
      },
      {
        icon: "/img/services/ser-fea-icon-2.png",
        title: "Patient-Focused Approach",
        items: [
          "Every treatment is tailored to your individual needs to achieve your desired smile.",
          "We offer flexible scheduling and financing options to make cosmetic treatments more accessible.",
          "Our friendly, experienced team ensures a comfortable and stress-free experience, making every visit enjoyable.",
        ]
      }
    ],
    // chartData: [
    //   { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    //   { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 }
    // ],
    testimonials: {
      text: "من أفضل واحسن عيادات الأسنان و دكاتره محترمين جدا و وفاهمين شغلهم كويس حقيقي فريق عمل ممتاز ومواعيد مظبوطه 👌",
      author: "Hager Mahmoud",
      position: "",
      image: ""
    },
    downloadsTitle: "Free Cosmetic Dentistry Guides.",
    downloads: [
      { icon: "/img/icon/pdf.png", title: "Cosmetic Dentistry eBooks and Guides – Enhance Your Smile Knowledge", size: "57KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Cosmetic Dentistry – Learn About Veneers, Whitening, and More", size: "87KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Cosmetic Dentistry Brochures & Resources – Your Guide to a Perfect Smile", size: "112KB", link: "#" }
    ],
    relatedServices: [
      { id: 2, icon: "/img/services/more-ser-1.png", title: "Dental Implants" },
      { id: 3, icon: "/img/services/more-ser-2.png", title: "Pediatric Dentistry" },
      { id: 4, icon: "/img/services/more-ser-3.png", title: "Orthodontics" },
      { id: 5, icon: "/img/services/more-ser-4.png", title: "Restorative Dentistry" },
      { id: 6, icon: "/img/services/more-ser-5.png", title: "Root canal treatment" }

    ]
    },
    
    {
    id: 2, // Unique ID for the service
    title: "Dental Implants in Maadi",
    mainImage: "/img/services/service-thumb-2.png",
    description: "We are here to help when you need us.",
    paragraphs: [
      "If you're dealing with missing teeth or tooth loss, dental implants are the most effective and long-lasting solution. At our dental clinic in Maadi, Cairo, we specialize in dental implant surgery, offering a permanent and natural-looking option to restore your smile. Whether you need a single tooth implant or a full-mouth restoration, our experienced team uses advanced techniques and high-quality titanium implants to ensure the best results. Read on to learn more about how dental implants can transform your oral health and boost your confidence.",

      "Cosmetic Dentistry focuses on improving the appearance of your teeth, gums, and overall smile. It involves a range of procedures that address tooth discoloration, chipped or misaligned teeth, and gummy smiles. Cosmetic treatments not only enhance the look of your smile but also boost self-confidence. Our Maadi dental clinic specializes in the following cosmetic procedures:",

      "Teeth whitening is one of the most popular cosmetic dental treatments. We use advanced techniques to safely remove stains caused by coffee, tea, smoking, or aging, resulting in a visibly brighter smile. Our clinic offers both in-office teeth whitening and take-home whitening kits to suit your convenience.",
      ],
    
    banner: {
    image: "/img/services/services-banner.png",
    link: "/contact",
    alt: "Service Banner"
  },

   headings: [
  {
    type: "h2",
    text: "Introduction",
    content: [
      { type: "paragraph", text: "If you're dealing with missing teeth or tooth loss, dental implants are the most effective and long-lasting solution. At our dental clinic in Maadi, Cairo, we specialize in dental implant surgery, offering a permanent and natural-looking option to restore your smile. Whether you need a single tooth implant or a full-mouth restoration, our experienced team uses advanced techniques and high-quality titanium implants to ensure the best results. Read on to learn more about how dental implants can transform your oral health and boost your confidence." },
      { type: "h3", text: "" },
      { type: "paragraph", text: "" },
    ]
  },
  {
    type: "h2",
    text: "What Are Dental Implants?",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Dental implants are artificial tooth roots made from titanium that are surgically placed into the jawbone to support a replacement tooth or bridge. This procedure is ideal for individuals who have lost one or more teeth due to injury, decay, or other dental issues. Unlike dentures, implants are permanent and function like natural teeth, allowing you to eat, speak, and smile with confidence." },
    ]
  },
  {
    type: "h2",
    text: "The Dental Implant Procedure",
    content: [
      { type: "h3", text: "Initial Consultation and Examination" },
      { type: "paragraph", text: "During your first visit, our implant dentist will conduct a comprehensive exam, including X-rays and possibly a CT scan, to assess the condition of your jawbone and overall oral health. This helps determine if you are a good candidate for dental implants or if additional procedures like bone grafting are necessary." },
    ]
  },
  {
    type: "h2",
    text: "",
    content: [
      { type: "h3", text: "Implant Placement Surgery" },
      { type: "paragraph", text: "Once your personalized treatment plan is created, the dental implant surgery is scheduled. Our dentist will place the titanium implant into your jawbone, which serves as a secure base for your replacement tooth. After the surgery, a healing period is required for the bone to bond with the implant, a process called osseointegration." },
    ]
  },
  {
    type: "h2",
    text: "",
    content: [
      { type: "h3", text: "Attaching the Crown" },
      { type: "paragraph", text: "After healing (typically 3-6 months), a custom-made dental crown is attached to the implant, creating a natural-looking and fully functional tooth. This dental restoration is designed to match the shape, size, and color of your existing teeth." },
    ]
  },
  // {
  //   type: "h2",
  //   text: "",
  //   content: [
  //     { type: "h3", text: "Smile Makeovers" },
  //     { type: "paragraph", text: "A smile makeover combines multiple cosmetic dental procedures to completely transform the appearance of your smile. It may include veneers, crowns, teeth whitening, and even gum contouring depending on your unique needs. Our cosmetic dentist will work closely with you to develop a customized treatment plan that aligns with your aesthetic goals." },
  //   ]
  // },
  {
    type: "h2",
    text: "Who is a Candidate for Dental Implants?",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Most individuals with missing teeth can benefit from dental implants. However, candidates should have healthy gums and adequate jawbone structure to support the implant. For those who lack sufficient bone density, a bone grafting or sinus lift may be required to provide a stable foundation for the implant. Additionally, patients must be committed to maintaining good oral hygiene and regular dental visits, as these factors play a significant role in the long-term success of dental implants. Patients suffering from chronic conditions such as diabetes or those who smoke heavily may face additional risks during the healing process, but these issues can often be addressed during the initial consultation." },
      { type: "h3", text: "What If I Don’t Have Enough Bone for Implants?" },
      { type: "paragraph", text: "If you have experienced significant bone loss, you may still be a candidate for implants with the help of bone grafting. This procedure helps regenerate bone in the jaw, providing a stable foundation for the implants." },
    ], 
  },
  {
    type: "h2",
    text: "Types of Dental Implants We Offer",
    content: [
    
      { type: "h3", text: "" },
      { type: "paragraph", text: "Our clinic in Maadi, Cairo provides various types of dental implants tailored to the unique needs of each patient. For patients missing a single tooth, a single tooth implant is the ideal solution, offering a stable replacement without affecting surrounding teeth. Those missing multiple teeth can opt for implant-supported bridges or individual crowns to restore full functionality." },
      { type: "h3", text: "01 Single Tooth Implants" },
      { type: "paragraph", text: "For patients who have lost a single tooth, single tooth implants offer the most effective replacement option. Unlike bridges, which require adjacent teeth to be altered, single implants preserve natural tooth structure while providing a stable and durable solution." },
      { type: "h3", text: "02 Multiple Tooth Implants" },
      { type: "paragraph", text: "For patients missing several teeth, multiple tooth implants can be used to restore function and appearance. Implant-supported bridges or multiple crowns provide an excellent alternative to dentures, offering increased comfort and stability." },
      { type: "h3", text: "03 Full Mouth Implants" },
      { type: "paragraph", text: "For those missing all their teeth, implant-supported dentures such as the All-on-4 treatment provide a more secure and comfortable alternative to traditional removable dentures. Each implant is designed to mimic the appearance and feel of natural teeth, offering an aesthetic and functional solution for those suffering from extensive tooth loss." },
    ]
  },
  {
    type: "h2",
    text: "Why Choose Our Dental Clinic for Implants?",
    content: [
    
      { type: "h3", text: "" },
      { type: "paragraph", text: "Choosing the right provider for your dental implant surgery is crucial to achieving the best outcome. At our dental clinic in Maadi, Cairo, we offer advanced implant technology, ensuring precision and optimal results for every patient. Our team consists of highly trained implant specialists who are dedicated to providing personalized care and long-lasting solutions. We use the latest techniques and high-quality materials, ensuring your dental implants look natural and function seamlessly." },
      { type: "paragraph", text: "Additionally, we understand that the cost of dental implants can be a concern, which is why we offer flexible financing options to make this life-changing procedure accessible to all. From the initial consultation to follow-up care, our focus is on patient satisfaction and long-term results."},
    ]
  },
  {
    type: "h2",
    text: "FAQs About Dental Implants",
    content: [
    
      { type: "h3", text: "Q1: How long do dental implants last?" },
      { type: "paragraph", text: "A- With proper care, dental implants can last a lifetime. Regular dental visits and good oral hygiene practices are essential to maintaining their longevity." },
      { type: "h3", text: "Q2: Is the dental implant procedure painful?" },
      { type: "paragraph", text: "A: The procedure is performed under local anesthesia, ensuring that patients experience little to no discomfort during surgery. Post-surgery, any mild discomfort can typically be managed with over-the-counter pain relief medications." },
      { type: "h3", text: "Q3: Can dental implants improve my speech?" },
      { type: "paragraph", text: "A: Yes, dental implants can improve speech by providing stable, natural-feeling replacements for missing teeth, preventing the slurring or difficulty that sometimes occurs with removable dentures." },
    ]
  },
  {
    type: "h2",
    text: "Book Your Dental Implant Consultation",
    content: [
      { type: "paragraph", text: "If you're ready to restore your smile with dental implants, contact our Maadi dental clinic today to schedule your consultation. Our experienced team will guide you through the process, offering personalized care from start to finish. Whether you need a single tooth implant or a complete full-mouth restoration, we are committed to providing you with the best possible outcome." },
      {
          type: "paragraph",
          parts: [
            { text: "Check out our " },
            { link: { href: "/servicesDetails/1", text: " Cosmetic Dentistry Services" } },
            { text: " to enhance your smile further." }
          ]
      },
      {
          type: "paragraph",
          parts: [
            { text: "Learn more about dental implants from the " },
            { link: { href: "https://aacd.com/", text: "American Academy of Cosmetic Dentistry" } },
          ]
        },
    ]
  },
],

    features: [
      {
        icon: "/img/services/ser-fea-icon-1.png",
        title: "Advanced Cosmetic Techniques",
        items: [
      "Our clinic utilizes the latest 3D imaging and digital planning software to ensure precise implant placement and optimal results for each patient.",
      "We offer minimally invasive dental implant surgery with state-of-the-art equipment, reducing recovery time and enhancing patient comfort.",
      "High-quality titanium implants and custom-made crowns are used to ensure durability, natural aesthetics, and long-lasting dental restoration."
    ]
      },
      {
        icon: "/img/services/ser-fea-icon-2.png",
        title: "Patient-Focused Approach",
        items: [
      "Every dental implant treatment is tailored to meet the individual needs of each patient, ensuring the best functional and aesthetic outcomes.",
      "We offer flexible scheduling and financing options, making dental implants more accessible and affordable for our patients.",
      "Our experienced team provides personalized care throughout the dental implant process, ensuring a smooth, stress-free experience from consultation to completion."
    ]
      }
    ],
    // chartData: [
    //   { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    //   { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 }
    // ],
    testimonials: {
      text: "أفضل مركز للاسنان و انا بتعامل معاهم من زمان ان شاء الله مزيد من النجاح والتوفيق دائما ",
      author: "waleed Abd El SalamAhmed Zain",
      position: "",
      image: ""
    },
    downloadsTitle: "Free Cosmetic Dentistry Guides.",
    downloads: [
      { icon: "/img/icon/pdf.png", title: "Free Dental Implant Guides – Everything You Need to Know", size: "57KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Comprehensive Dental Implant Guide – Restore Your Smile with Confidence", size: "87KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Essential Dental Implant Resources – Your Path to a Permanent Solution", size: "112KB", link: "#" }
    ],
    relatedServices: [
      { id: 1, icon: "/img/services/more-ser-6.png", title: "Cosmetic Dentistry" },
      { id: 3, icon: "/img/services/more-ser-2.png", title: "Pediatric Dentistry" },
      { id: 4, icon: "/img/services/more-ser-3.png", title: "Orthodontics" },
      { id: 5, icon: "/img/services/more-ser-4.png", title: "Restorative Dentistry" },
      { id: 6, icon: "/img/services/more-ser-5.png", title: "Root canal treatment" }
    ]
  },
    
    {
    id: 3, // Unique ID for the service
    title: "Pediatric Dentistry in Maadi",
    mainImage: "/img/services/service-thumb-3.png",
    description: "We are here to help when you need us.",
    paragraphs: [
      "If you're dealing with missing teeth or tooth loss, dental implants are the most effective and long-lasting solution. At our dental clinic in Maadi, Cairo, we specialize in dental implant surgery, offering a permanent and natural-looking option to restore your smile. Whether you need a single tooth implant or a full-mouth restoration, our experienced team uses advanced techniques and high-quality titanium implants to ensure the best results. Read on to learn more about how dental implants can transform your oral health and boost your confidence.",

      "Cosmetic Dentistry focuses on improving the appearance of your teeth, gums, and overall smile. It involves a range of procedures that address tooth discoloration, chipped or misaligned teeth, and gummy smiles. Cosmetic treatments not only enhance the look of your smile but also boost self-confidence. Our Maadi dental clinic specializes in the following cosmetic procedures:",

      "Teeth whitening is one of the most popular cosmetic dental treatments. We use advanced techniques to safely remove stains caused by coffee, tea, smoking, or aging, resulting in a visibly brighter smile. Our clinic offers both in-office teeth whitening and take-home whitening kits to suit your convenience.",
    ],

    banner: {
    image: "/img/services/services-banner2.png",
    link: "/contact",
    alt: "Service Banner"
  },

   headings: [
  {
    type: "h2",
    text: "Introduction",
    content: [
      { type: "paragraph", text: "Your child's dental health plays a crucial role in their overall well-being. At our Pediatric Dentistry clinic in Maadi, Cairo, we specialize in providing gentle and compassionate dental care for children of all ages. From the first dental visit to cavity prevention and comprehensive pediatric oral care, our goal is to ensure a positive experience that sets the foundation for a lifetime of healthy smiles. Our kid-friendly environment and specialized techniques make visits to the dentist enjoyable and stress-free for both children and parents." },
      { type: "h3", text: "" },
      { type: "paragraph", text: "" },
    ]
  },
  {
    type: "h2",
    text: "Innovative Pediatric Dental Treatments for Every Child’s Needs",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "At our Pediatric Dentistry clinic in Maadi, Cairo, we understand that every child’s dental health journey is unique. Our team combines cutting-edge dental technology with gentle, child-friendly care to ensure that each patient receives the best possible treatment for their specific needs. From preventive care to advanced dental solutions, we offer a variety of treatments designed to meet the evolving needs of growing children." },
      { type: "h3", text: "Modern Pediatric Treatments Designed for Long-Term Oral Health" },
      { type: "paragraph", text: "Our focus is not just on immediate solutions, but on long-term dental health. We use the latest minimally invasive techniques to treat common childhood dental issues such as cavities, misaligned teeth, and gum health concerns. Procedures like dental sealants, fluoride treatments, and early orthodontic interventions help protect your child’s teeth from decay and ensure proper dental development as they grow." },
      { type: "paragraph", text: "Our clinic also uses digital X-rays and advanced imaging tools to create precise treatment plans that are tailored to each child’s oral health needs. These technologies allow us to detect and address issues early, preventing more serious problems later on." },
      { type: "h3", text: " Preventive and Proactive Pediatric Care" },
      { type: "paragraph", text: "We believe that prevention is the cornerstone of good oral health, which is why our pediatric services emphasize regular check-ups and proactive treatments. By identifying potential issues early on, we can address them before they escalate, ensuring that your child enjoys a lifetime of healthy teeth. Our team works closely with parents to provide education on at-home care, including brushing techniques, diet recommendations, and tips for maintaining strong teeth between visits." },
    ]
  },
  {
    type: "h2",
    text: "Common Pediatric Dentistry Services",
    content: [
      { type: "h3", text: "01 Cavity Prevention with Dental Sealants and Fluoride Treatments" },
      { type: "paragraph", text: "Dental sealants are an excellent way to prevent cavities in the molars, which are hard to reach when brushing. By sealing the deep grooves in your child’s teeth, we help protect them from decay. Additionally, fluoride treatments strengthen the enamel, making teeth more resistant to cavities. These treatments are quick, painless, and provide long-lasting protection for your child's developing teeth." },
    ]
  },
  {
    type: "h2",
    text: "",
    content: [
      { type: "h3", text: "Treatment for Cavities and Tooth Decay" },
      { type: "paragraph", text: "Even with good care, some children may develop cavities. Our pediatric dental care includes gentle, effective treatments for cavities and tooth decay. We use modern techniques and child-friendly approaches to ensure your little one feels comfortable and safe during their treatment. Restorative procedures like tooth-colored fillings are used to preserve the appearance and health of your child’s teeth." },
    ]
  },

  // {
  //   type: "h2",
  //   text: "",
  //   content: [
  //     { type: "h3", text: "Smile Makeovers" },
  //     { type: "paragraph", text: "A smile makeover combines multiple cosmetic dental procedures to completely transform the appearance of your smile. It may include veneers, crowns, teeth whitening, and even gum contouring depending on your unique needs. Our cosmetic dentist will work closely with you to develop a customized treatment plan that aligns with your aesthetic goals." },
  //   ]
  // },
  {
    type: "h2",
    text: "Why Choose Our Pediatric Dentistry Clinic?",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Choosing the right dental care provider for your child is essential to fostering a positive relationship with oral hygiene. Our Pediatric Dentistry clinic in Maadi, Cairo offers specialized care designed to cater to children's unique needs. From the moment your child steps into our kid-friendly waiting room, they’ll feel at ease. Our experienced team focuses on making every visit enjoyable and educational, empowering children to take responsibility for their oral health." },
      { type: "paragraph", text: "We understand that dental visits can be intimidating for young children. That’s why we use gentle, non-invasive techniques and create a welcoming atmosphere that reduces dental anxiety. By offering flexible appointment times and personalized treatment plans, we ensure that every child receives the best possible care." },
    ], 
  },
  {
    type: "h2",
    text: "When Should My Child Visit a Pediatric Dentist?",
    content: [
    
      { type: "h3", text: "" },
      { type: "paragraph", text: "It’s recommended that children have their first dental visit by their first birthday or when their first tooth appears. Early visits allow us to monitor the development of your child's teeth and prevent potential dental issues from becoming more serious. Regular check-ups are essential in building a preventive dental care routine that will benefit your child throughout their life." },
    ]
  },
  
  {
    type: "h2",
    text: "FAQs About Pediatric Dentistry",
    content: [
    
      { type: "h3", text: "Q1: How often should my child visit the dentist?" },
      { type: "paragraph", text: "A- Children should visit the dentist every six months for regular cleanings and exams. This helps prevent cavities and keeps their teeth healthy." },
      { type: "h3", text: "Q2: Are dental X-rays safe for children?" },
      { type: "paragraph", text: "A: Yes, dental X-rays are safe for children. We use low-radiation digital X-rays to monitor the development of your child’s teeth and detect any issues that aren’t visible during a standard exam." },
      { type: "h3", text: "Q3: How can I help my child prevent cavities?" },
      { type: "paragraph", text: "A: Ensure your child brushes their teeth twice a day with fluoride toothpaste and flosses daily. Avoid sugary snacks and drinks, and bring them in for regular dental check-ups and fluoride treatments." },
    ]
  },
  {
    type: "h2",
    text: "Book Your Pediatric Dentistry Appointment in Maadi",
    content: [
      { type: "paragraph", text: "At our Pediatric Dentistry clinic in Maadi, Cairo, we’re dedicated to providing exceptional dental care that helps children develop healthy habits and a lifetime of confident smiles. Schedule your child’s appointment today and let our experienced team guide them on the path to optimal oral health." },
      {
          type: "paragraph",
          parts: [
            { text: "Check out our " },
            { link: { href: "/servicesDetails/1", text: " Cosmetic Dentistry Services" } },
            { text: " to enhance your smile further." }
          ]
      },
      {
          type: "paragraph",
          parts: [
            { text: "Learn more about dental implants from the " },
            { link: { href: "https://www.aapd.org/publications/journals/pediatric-dentistry-journal/", text: " American Academy of Pediatric Dentistry." } },
          ]
        },
    ]
  },
],

    features: [
      {
        icon: "/img/services/ser-fea-icon-1.png",
        title: "Advanced Cosmetic Techniques",
        items: [
      "Our clinic offers a calm, child-friendly environment to ensure your child feels comfortable and safe during every visit.",
      "We use advanced, minimally invasive techniques for pediatric treatments, making procedures as pain-free as possible.",
      "Our team is specially trained in handling children's dental needs, focusing on preventive care and early intervention to keep their smiles healthy."
    ]
      },
      {
        icon: "/img/services/ser-fea-icon-2.png",
        title: "Patient-Focused Approach",
        items: [
      "From dental sealants to fluoride treatments, we offer a wide range of preventive care options tailored to protect your child’s teeth.",
      "We focus on early detection and treatment of dental issues, helping prevent cavities and ensure proper dental development.",
      "Our flexible scheduling and personalized care plans make it easy for parents to keep their children’s oral health on track."
    ]
      }
    ],
    // chartData: [
    //   { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    //   { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 }
    // ],
    testimonials: {
      text: "My daughter used to be nervous about visiting the dentist, but after coming to this clinic, she actually looks forward to her appointments! The team is so gentle and patient, and the kid-friendly environment really helps. ",
      author: "Fatma Yasser",
      position: "",
      image: ""
    },
    downloadsTitle: "Free Cosmetic Dentistry Guides.",
    downloads: [
      { icon: "/img/icon/pdf.png", title: "Free Pediatric Dental Care Guide – Healthy Habits for Little Smiles", size: "57KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Essential Dental Care Tips for Kids – Download Your Guide Today", size: "87KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Pediatric Dentistry Resources – Helping You Protect Your Child's Smile", size: "112KB", link: "#" }
    ],
    relatedServices: [
      { id: 1, icon: "/img/services/more-ser-6.png", title: "Cosmetic Dentistry" },
      { id: 2, icon: "/img/services/more-ser-1.png", title: "Dental Implants" },
      { id: 4, icon: "/img/services/more-ser-3.png", title: "Orthodontics" },
      { id: 5, icon: "/img/services/more-ser-4.png", title: "Restorative Dentistry" },
      { id: 6, icon: "/img/services/more-ser-5.png", title: "Root canal treatment" }

    ]
    },
  // Other services...
    
  {
    id: 4, // Unique ID for the service
    title: "Orthodontics in Maadi",
    mainImage: "/img/services/service-thumb-4.png",
    description: "From braces to Invisalign.",
    paragraphs: [
      "If you're dealing with missing teeth or tooth loss, dental implants are the most effective and long-lasting solution. At our dental clinic in Maadi, Cairo, we specialize in dental implant surgery, offering a permanent and natural-looking option to restore your smile. Whether you need a single tooth implant or a full-mouth restoration, our experienced team uses advanced techniques and high-quality titanium implants to ensure the best results. Read on to learn more about how dental implants can transform your oral health and boost your confidence.",

      "Cosmetic Dentistry focuses on improving the appearance of your teeth, gums, and overall smile. It involves a range of procedures that address tooth discoloration, chipped or misaligned teeth, and gummy smiles. Cosmetic treatments not only enhance the look of your smile but also boost self-confidence. Our Maadi dental clinic specializes in the following cosmetic procedures:",

      "Teeth whitening is one of the most popular cosmetic dental treatments. We use advanced techniques to safely remove stains caused by coffee, tea, smoking, or aging, resulting in a visibly brighter smile. Our clinic offers both in-office teeth whitening and take-home whitening kits to suit your convenience.",
    ],

    banner: {
    image: "/img/services/services-banner3.png",
    link: "/contact",
    alt: "Service Banner"
  },

   headings: [
  {
    type: "h2",
    text: "Introduction",
    content: [
      { type: "paragraph", text: "Achieving a straight, healthy smile has never been easier with our Orthodontics services in Maadi, Cairo. Whether you’re looking for traditional braces or the modern convenience of Invisalign, our orthodontic experts will design a personalized treatment plan to give you the smile you’ve always wanted. We provide specialized care for both children and adults, focusing on correcting misaligned teeth, improving bite function, and enhancing your overall oral health. Explore our range of orthodontic solutions and start your journey to a more confident smile today." },
      { type: "h3", text: "" },
      { type: "paragraph", text: "" },
    ]
  },
  {
    type: "h2",
    text: "Types of Orthodontic Treatments We Offer",
    content: [
      { type: "h3", text: "Traditional Metal Braces" },
      { type: "paragraph", text: "Metal braces remain one of the most effective methods for straightening teeth. These braces use metal brackets and wires to gradually shift your teeth into proper alignment. Suitable for both children and adults, metal braces are a durable and reliable option for addressing even severe alignment issues. With today’s technology, modern braces are more comfortable and efficient, providing faster results compared to traditional methods." },
      { type: "h3", text: "Ceramic Braces" },
      { type: "paragraph", text: "Ceramic braces offer the same reliable teeth straightening as metal braces but with the added advantage of being less visible. The brackets are made from clear or tooth-colored ceramic material, blending in with your natural teeth. These braces are an excellent choice for individuals seeking a more discreet treatment while still benefiting from the effectiveness of traditional braces." },
      { type: "h3", text: "Ceramic Braces" },
      { type: "paragraph", text: "For those looking for a more flexible and nearly invisible option, Invisalign is a popular choice. Invisalign uses a series of custom-made, removable clear aligners that gradually move your teeth into their desired position. These aligners are perfect for individuals who want the convenience of removing the appliance for eating and cleaning. Invisalign is also highly recommended for adults or teens seeking a more aesthetic solution to teeth straightening." },
      { type: "h3", text: "Lingual Braces" },
      { type: "paragraph", text: "Lingual braces are an innovative option for those seeking a hidden orthodontic solution. Unlike traditional braces, lingual braces are attached to the back of the teeth, making them invisible from the front. This treatment is ideal for individuals who want to straighten their teeth without compromising the appearance of their smile during treatment." },
    ]
  },
  {
    type: "h2",
    text: "How Orthodontic Treatment Works",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Orthodontic treatments work by applying continuous pressure to your teeth, gradually moving them into the correct position over time. After an initial orthodontic consultation, we’ll evaluate your teeth and jaw alignment using digital X-rays and 3D imaging. This helps us create a customized treatment plan based on your specific needs. Whether you choose braces or clear aligners, we’ll guide you through each step of the process to ensure that your treatment is as comfortable and effective as possible." },
    ]
  },
  {
    type: "h2",
    text: "Orthodontics for Children and Adults",
    content: [
      { type: "h3", text: "Early Orthodontic Treatment for Children" },
      { type: "paragraph", text: "Starting orthodontic treatment early can prevent more complex dental issues later in life. We recommend that children visit for an orthodontic evaluation by age 7. This allows us to assess jaw growth and dental development and to begin early treatments if necessary. Early orthodontic treatment can help correct bite problems, guide proper jaw alignment, and create space for permanent teeth to emerge." },
      { type: "h3", text: "Adult Orthodontic Treatment" },
      { type: "paragraph", text: "It’s never too late to achieve a beautiful smile. Adult orthodontics is becoming increasingly popular as more people seek to improve their teeth alignment and oral health. With discreet options like ceramic braces and Invisalign, adults can enjoy the benefits of straighter teeth without the appearance of traditional metal braces." },
    ]
  },

  // {
  //   type: "h2",
  //   text: "",
  //   content: [
  //     { type: "h3", text: "Smile Makeovers" },
  //     { type: "paragraph", text: "A smile makeover combines multiple cosmetic dental procedures to completely transform the appearance of your smile. It may include veneers, crowns, teeth whitening, and even gum contouring depending on your unique needs. Our cosmetic dentist will work closely with you to develop a customized treatment plan that aligns with your aesthetic goals." },
  //   ]
  // },
  {
    type: "h2",
    text: "Why Choose Us for Orthodontic Treatment?",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Our clinic is committed to providing the best orthodontic care in Maadi, Cairo, using the latest technology and techniques to ensure successful outcomes. We offer a range of treatments tailored to fit your needs, whether you’re looking for a traditional approach or something more discreet like Invisalign. Our experienced orthodontists take the time to create personalized treatment plans for each patient, ensuring comfort and precision every step of the way." },
      { type: "paragraph", text: "We also offer flexible payment plans to make orthodontic treatment affordable for everyone. From your first consultation to your final results, we’re here to support you throughout your orthodontic journey." },
    ], 
  },
  
  {
    type: "h2",
    text: "FAQs About Orthodontics",
    content: [
    
      { type: "h3", text: "Q1: How long does orthodontic treatment take?  " },
      { type: "paragraph", text: "A- The duration of orthodontic treatment varies depending on the complexity of the case. Most treatments take between 12 to 24 months, but your orthodontist will provide a more accurate timeline during your consultation." },
      { type: "h3", text: "Q3: Are there any food restrictions with braces?" },
      { type: "paragraph", text: "A: Yes, certain foods like hard candies, sticky sweets, and crunchy snacks should be avoided as they can damage braces. Invisalign offers more flexibility since the aligners are removable." },
      { type: "h3", text: "Q3: Will I need to wear a retainer after my orthodontic treatment?" },
      { type: "paragraph", text: "A: Yes, retainers are essential after any orthodontic treatment to maintain the new position of your teeth and prevent them from shifting back." },
    ]
  },
  {
    type: "h2",
    text: "Book Your Orthodontic Consultation in Maadi",
    content: [
      { type: "paragraph", text: "If you’re ready to start your journey toward a straighter, healthier smile, contact our Orthodontics clinic in Maadi, Cairo to schedule a consultation. Our team is dedicated to helping you achieve the smile you’ve always dreamed of, using the latest orthodontic techniques tailored to your specific needs." },
      {
          type: "paragraph",
          parts: [
            { text: "Learn more about our " },
            { link: { href: "/servicesDetails/3", text: " Pediatric Dentistry Services." } },
            { text: " to enhance your kid's smile further." }
          ]
      },
      {
          type: "paragraph",
          parts: [
            { text: "Learn about modern orthodontic treatments at the " },
            { link: { href: "https://aaoinfo.org//", text: " American Association of Orthodontists." } },
          ]
        },
    ]
  },
],

    features: [
  {
    icon: "/img/services/ser-fea-icon-1.png",
    title: "Advanced Orthodontic Technology",
    items: [
      "We use 3D imaging and digital scanning to design precise orthodontic treatment plans, ensuring faster and more comfortable results.",
      "Our clinic offers cutting-edge solutions like Invisalign and lingual braces for a discreet and effective teeth-straightening experience.",
      "We prioritize patient comfort with modern orthodontic appliances, reducing treatment times and improving overall effectiveness."
    ]
  },
  {
    icon: "/img/services/ser-fea-icon-2.png",
    title: "Personalized Orthodontic Care",
    items: [
      "Each patient receives a custom treatment plan based on their unique dental needs, ensuring optimal results.",
      "Our experienced team provides continuous support throughout the entire treatment process, from the first consultation to final retainer fitting.",
      "We offer flexible payment options to make orthodontic treatment affordable for all patients, regardless of the treatment type."
    ]
  }
],
    // chartData: [
    //   { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    //   { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 }
    // ],
    testimonials: {
      text: "من أفضل واحسن عيادات الأسنان و دكاتره محترمين جدا و وفاهمين شغلهم كويس حقيقي فريق عمل ممتاز ومواعيد مظبوطه👌 ",
      author: "Hager Mahmoudd",
      position: "",
      image: ""
    },
    downloadsTitle: "Free Cosmetic Dentistry Guides.",
    downloads: [
      { icon: "/img/icon/pdf.png", title: "Free Orthodontic Treatment Guide – Your Path to a Straighter Smile", size: "57KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Comprehensive Orthodontics Guide – Learn About Braces, Invisalign, and More", size: "87KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Download Our Orthodontics Care Handbook – Everything You Need to Know", size: "112KB", link: "#" }
    ],
    relatedServices: [
      { id: 1, icon: "/img/services/more-ser-6.png", title: "Cosmetic Dentistry" },
      { id: 2, icon: "/img/services/more-ser-1.png", title: "Dental Implants" },
      { id: 3, icon: "/img/services/more-ser-2.png", title: "Pediatric Dentistry" },
      { id: 5, icon: "/img/services/more-ser-4.png", title: "Restorative Dentistry" },
      { id: 6, icon: "/img/services/more-ser-5.png", title: "Root canal treatment" }
    ]
  },
  
  {
    id: 5, // Unique ID for the service
    title: "Restore Missing Teeth with Crowns & Bridges",
    mainImage: "/img/services/service-thumb-5.png",
    description: "From braces to Invisalign.",
    paragraphs: [
      "If you're dealing with missing teeth or tooth loss, dental implants are the most effective and long-lasting solution. At our dental clinic in Maadi, Cairo, we specialize in dental implant surgery, offering a permanent and natural-looking option to restore your smile. Whether you need a single tooth implant or a full-mouth restoration, our experienced team uses advanced techniques and high-quality titanium implants to ensure the best results. Read on to learn more about how dental implants can transform your oral health and boost your confidence.",

      "Cosmetic Dentistry focuses on improving the appearance of your teeth, gums, and overall smile. It involves a range of procedures that address tooth discoloration, chipped or misaligned teeth, and gummy smiles. Cosmetic treatments not only enhance the look of your smile but also boost self-confidence. Our Maadi dental clinic specializes in the following cosmetic procedures:",

      "Teeth whitening is one of the most popular cosmetic dental treatments. We use advanced techniques to safely remove stains caused by coffee, tea, smoking, or aging, resulting in a visibly brighter smile. Our clinic offers both in-office teeth whitening and take-home whitening kits to suit your convenience.",
    ],

    banner: {
    image: "/img/services/services-banner4.png",
    link: "/contact",
    alt: "Service Banner"
  },

   headings: [
  {
    type: "h2",
    text: "Introduction",
    content: [
      { type: "paragraph", text: "At our dental clinic in Maadi, we specialize in restorative dentistry, offering comprehensive solutions to repair damaged teeth and replace missing ones. Our advanced restorative treatments, including crowns, bridges, and dental implants, are designed to restore both the function and appearance of your teeth. Whether you need to repair a cracked tooth or replace multiple missing teeth, our expert team is dedicated to providing personalized care that meets your dental health needs. With our focus on durability and aesthetics, you can enjoy a healthy, natural-looking smile for years to come." },
      { type: "h3", text: "" },
      { type: "paragraph", text: "" },
    ]
  },
  {
    type: "h2",
    text: "Customized Restorative Dental Care for Every Patient",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "Our approach to restorative dental care is based on the unique needs of each patient. From simple repairs like fillings to complex treatments such as full mouth reconstruction, we tailor every procedure to address your specific dental issues. Using high-quality materials and advanced techniques, our goal is to provide solutions that restore both the functionality and aesthetics of your teeth." },
      { type: "h3", text: "Repairing Damaged Teeth with Crowns" },
      { type: "paragraph", text: "CWhen a tooth is too damaged for a filling but can still be saved, a dental crown is often the best option. Crowns are custom-designed to cover the entire tooth, reinforcing its structure while enhancing its appearance. Made from materials like porcelain or ceramic, crowns are durable and designed to blend seamlessly with your natural teeth. They are commonly used after root canal treatments or to repair teeth that have been fractured, worn down, or severely decayed." },
      { type: "h3", text: "Replacing Missing Teeth with Fixed Bridges" },
      { type: "paragraph", text: "For patients who have lost one or more teeth, fixed dental bridges offer a reliable solution. A bridge consists of one or more false teeth anchored by crowns placed on the adjacent healthy teeth. This creates a stable, long-lasting restoration that restores your ability to chew, speak, and smile confidently. Fixed bridges are an excellent alternative for those seeking a non-surgical option for replacing missing teeth." },
    ]
  },
  {
    type: "h2",
    text: "Long-Lasting Tooth Replacement Solutions",
    content: [
      { type: "h3", text: "" },
      { type: "paragraph", text: "One of the most significant advances in restorative dentistry is the ability to provide long-lasting tooth replacement options. Dental implants and bridges offer reliable alternatives for patients with missing teeth, providing both functional and aesthetic benefits that can last for years with proper care." },
      { type: "h3", text: "Advanced Technology for Precision Restorations" },
      { type: "paragraph", text: "Our clinic uses the latest technology to ensure precision in every restoration. From the initial consultation to the final placement of your crown or bridge, we rely on advanced tools to create accurate, custom-fit restorations that match your natural bite and jaw alignment. This technology allows us to deliver results that are not only highly functional but also aesthetically pleasing." },
      { type: "h3", text: "Preventing Future Dental Issues with Restorative Care" },
      { type: "paragraph", text: "Restorative dentistry doesn’t just fix existing problems—it also helps prevent future dental issues. Replacing missing teeth with implants or bridges ensures that your remaining teeth stay properly aligned, reducing the risk of further tooth loss or jawbone deterioration. Regular check-ups and maintenance after restorative treatments are essential for long-term dental health, helping to avoid more complex procedures in the future." },
    ]
     },
  
  {
    type: "h2",
    text: "Why Choose Our Maadi Clinic for Restorative Dentistry?",
    content: [
      { type: "paragraph", text: "At our clinic, we focus on delivering personalized and high-quality restorative dental solutions to every patient. Our team is dedicated to staying up-to-date with the latest dental technologies and techniques to ensure your treatment is comfortable, efficient, and effective. From the moment you step into our clinic, we prioritize your comfort, offering tailored treatments and flexible payment options to make your restorative dentistry journey smooth and stress-free." },
      
    ]
  },

  // {
  //   type: "h2",
  //   text: "",
  //   content: [
  //     { type: "h3", text: "Smile Makeovers" },
  //     { type: "paragraph", text: "A smile makeover combines multiple cosmetic dental procedures to completely transform the appearance of your smile. It may include veneers, crowns, teeth whitening, and even gum contouring depending on your unique needs. Our cosmetic dentist will work closely with you to develop a customized treatment plan that aligns with your aesthetic goals." },
  //   ]
  // },
  
  {
    type: "h2",
    text: "FAQs About Restorative Dentistry",
    content: [
    
      { type: "h3", text: "Q1: How long do crowns and bridges last?" },
      { type: "paragraph", text: "A- With proper care, dental crowns and bridges can last 10-15 years or longer. Regular dental visits and good oral hygiene are essential for maintaining their longevity." },
      { type: "h3", text: "Q3: Is the restorative dental process painful?" },
      { type: "paragraph", text: "A: Most restorative dentistry procedures, including the placement of crowns and bridges, are performed under local anesthesia to ensure patient comfort. Any discomfort after the procedure is typically mild and can be managed with over-the-counter pain relief." },
    ]
  },
  {
    type: "h2",
    text: "Restore Your Smile with Restorative Dentistry",
    content: [
      { type: "paragraph", text: "If you’re looking to repair damaged teeth or replace missing ones, our expert restorative dentistry services in Maadi, Cairo can help you achieve a healthy, beautiful smile. Contact us today to schedule a consultation and find the right solution for your dental needs." },
      {
          type: "paragraph",
          parts: [
            { text: "Learn more about our " },
            { link: { href: "/servicesDetails/1", text: " Cosmetic Dentistry Services." } },
          ]
      },
      {
          type: "paragraph",
          parts: [
            { text: "Discover more about restorative dental treatments at the " },
            { link: { href: "https://my.clevelandclinic.org/health/treatments/10921-dental-bridges", text: "clevelandclinic" } },
          ]
        },
    ]
  },
],

    features: [
  {
    icon: "/img/services/ser-fea-icon-1.png",
    title: "Comprehensive Tooth Restoration",
    items: [
      "We offer a full range of restorative dental services, including crowns, bridges, and implants, tailored to meet each patient's needs.",
      "Our clinic uses advanced materials and technologies to ensure that your restorations are durable, functional, and natural-looking.",
      "Each treatment plan is personalized, focusing on both the functional and cosmetic aspects of your dental health."
    ]
  },
  {
    icon: "/img/services/ser-fea-icon-2.png",
    title: "Long-Term Solutions for Missing Teeth",
    items: [
      "We provide permanent solutions for missing teeth with dental implants and bridgework, ensuring long-lasting, stable results.",
      "Our experienced team focuses on restoring both the appearance and function of your smile, improving your overall quality of life.",
      "We offer flexible financing options to make our restorative dentistry services accessible and affordable."
    ]
  }
],
    // chartData: [
    //   { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    //   { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 }
    // ],
    testimonials: {
      text: "من أفضل واحسن عيادات الأسنان و دكاتره محترمين د/احمد زغلول من اشطر وافضل الدكاتره الواحد قابلهم في حياته وال staff كله محترم وشاطر ❤️جدا و وفاهمين شغلهم كويس حقيقي فريق عمل ممتاز ومواعيد مظبوطه👌 ",
      author: "Islam Ashour",
      position: "",
      image: ""
    },
    downloadsTitle: "Free Cosmetic Dentistry Guides.",
    downloads: [
      { icon: "/img/icon/pdf.png", title: "Complete Guide to Restorative Dentistry – Everything You Need to Know", size: "57KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Restorative Dentistry Explained – Free Downloadable Guide for Patients", size: "87KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "How to Repair and Replace Damaged Teeth – Download Your Guide", size: "112KB", link: "#" }
    ],
    relatedServices: [
      { id: 1, icon: "/img/services/more-ser-6.png", title: "Cosmetic Dentistry" },
      { id: 2, icon: "/img/services/more-ser-1.png", title: "Dental Implants" },
      { id: 3, icon: "/img/services/more-ser-2.png", title: "Pediatric Dentistry" },
      { id: 4, icon: "/img/services/more-ser-3.png", title: "Orthodontics" },
      { id: 6, icon: "/img/services/more-ser-5.png", title: "Root canal treatment" }

    ]
  },

  {
    id: 6, // Unique ID for the service
    title: "Root Canal Treatment in Maadi",
    mainImage: "/img/services/service-thumb-6.png",
    description: "Root Canal Treatment",
    paragraphs: [
      "If you're dealing with missing teeth or tooth loss, dental implants are the most effective and long-lasting solution. At our dental clinic in Maadi, Cairo, we specialize in dental implant surgery, offering a permanent and natural-looking option to restore your smile. Whether you need a single tooth implant or a full-mouth restoration, our experienced team uses advanced techniques and high-quality titanium implants to ensure the best results. Read on to learn more about how dental implants can transform your oral health and boost your confidence.",

      "Cosmetic Dentistry focuses on improving the appearance of your teeth, gums, and overall smile. It involves a range of procedures that address tooth discoloration, chipped or misaligned teeth, and gummy smiles. Cosmetic treatments not only enhance the look of your smile but also boost self-confidence. Our Maadi dental clinic specializes in the following cosmetic procedures:",

      "Teeth whitening is one of the most popular cosmetic dental treatments. We use advanced techniques to safely remove stains caused by coffee, tea, smoking, or aging, resulting in a visibly brighter smile. Our clinic offers both in-office teeth whitening and take-home whitening kits to suit your convenience.",
    ],

    banner: {
    image: "/img/services/services-banner6.png",
    link: "/contact",
    alt: "Service Banner"
  },

   headings: [
  {
    type: "h2",
    text: "Introduction",
    content: [
      { type: "paragraph", text: "If you're experiencing severe tooth pain, sensitivity, or swelling around your tooth, you may need a root canal treatment to address an infection deep within the tooth. Our Maadi clinic specializes in root canal therapy, a procedure designed to save your natural tooth by removing the infected or inflamed dental pulp, relieving pain, and restoring the tooth's function. With advanced techniques and a focus on patient comfort, we ensure that your root canal is a stress-free experience, preserving your tooth and preventing further complications." },
      { type: "h3", text: "" },
      { type: "paragraph", text: "" },
    ]
  },
  {
    type: "h2",
    text: "Signs You Might Need a Root Canal",
    content: [
      { type: "h3", text: "Persistent Tooth Pain" },
      { type: "paragraph", text: "If you're experiencing persistent, throbbing pain in your tooth that doesn't go away, it could be a sign that the tooth pulp is infected or inflamed. This pain can be localized or may radiate to other parts of your face, jaw, or ear." },
      { type: "h3", text: "Sensitivity to Hot or Cold" },
      { type: "paragraph", text: "A lingering sensitivity to hot or cold food and drinks may suggest nerve damage inside the tooth, which could require endodontic treatment." },
      { type: "h3", text: "Swelling and Tenderness" },
      { type: "paragraph", text: "Swelling or tenderness around the gums near the affected tooth may indicate an abscess, which forms when infection spreads beyond the tooth root. This is a serious condition that requires immediate attention." },
      { type: "h3", text: "Darkening of the Tooth" },
      { type: "paragraph", text: "Discoloration or darkening of the tooth can indicate that the tooth pulp is damaged or dying, often necessitating a root canal" },
    ]
  },
  {
    type: "h2",
    text: "The Root Canal Procedure – What to Expect",
    content: [
      { type: "paragraph", text: "Many patients are concerned about the root canal process, but modern dentistry ensures that this procedure is safe, effective, and relatively painless." },
      { type: "h3", text: " Step 1: Diagnosis and Preparation" },
      { type: "paragraph", text: "During your consultation, our endodontist will take X-rays to assess the extent of the infection and develop a personalized treatment plan. Local anesthesia will be administered to numb the area, ensuring that you remain comfortable throughout the procedure." },
      { type: "h3", text: "Step 2: Pulp Removal and Cleaning" },
      { type: "paragraph", text: "The dentist will make a small opening in the top of the affected tooth and carefully remove the infected or damaged pulp. The inside of the tooth is then cleaned, disinfected, and shaped to prepare it for the filling material." },
      { type: "h3", text: "Step 3: Sealing the Tooth" },
      { type: "paragraph", text: "Once cleaned, the tooth is filled with a rubber-like material called gutta-percha to seal the space and prevent further infection. A temporary filling may be placed until the permanent dental crown is ready." },
      { type: "h3", text: "Step 4: Crown Placement" },
      { type: "paragraph", text: "A dental crown is often placed over the tooth to restore its full function and protect it from future damage. This step is crucial for ensuring the long-term success of your root canal treatment." },
    ]
     },
  
  {
    type: "h2",
    text: "Advantages of Root Canal Treatment",
    content: [
      { type: "paragraph", text: "Root canal therapy offers several significant benefits over tooth extraction:" },
      { type: "h3", text: "Saves Your Natural Tooth" },
      { type: "paragraph", text: "Preserving your natural tooth is always preferable to extraction, as it helps maintain your bite, jaw alignment, and the natural appearance of your smile." },
      { type: "h3", text: "Relieves Pain" },
      { type: "paragraph", text: "A root canal addresses the source of the infection, effectively eliminating the pain caused by inflamed dental pulp." },
      { type: "h3", text: "Prevents Further Complications" },
      { type: "paragraph", text: "By treating the infection, a root canal prevents the spread of bacteria to other teeth, gums, and surrounding bone, which could otherwise lead to more severe health issues." },
    ]
  },

  {
    type: "h2",
    text: "Why Choose Our Maadi Clinic for Root Canal Treatment?",
    content: [
      { type: "paragraph", text: "At our Maadi clinic, we use state-of-the-art technology and gentle techniques to make your root canal procedure as painless and efficient as possible. Our highly skilled team of dentists and endodontists are committed to preserving your natural teeth while ensuring your comfort throughout the process. We provide a welcoming environment and prioritize your dental health, offering personalized treatment plans tailored to your unique needs." },
    ]
  },

  {
    type: "h2",
    text: "FAQs About Root Canal Treatment",
    content: [
    
      { type: "h3", text: "Q1: How long does a root canal procedure take?" },
      { type: "paragraph", text: "Most root canal treatments can be completed in one or two appointments, depending on the complexity of the case. Each session usually lasts about 60 to 90 minutes." },
      { type: "h3", text: "Q2: Is a root canal painful?" },
      { type: "paragraph", text: "A: With modern anesthesia and techniques, root canal treatment is no more painful than getting a filling. Most patients feel relief from the pain caused by the infection after the procedure." },
      { type: "h3", text: "Q3: How long does a tooth last after a root canal?" },
      { type: "paragraph", text: "A: With proper care, a tooth treated with a root canal can last a lifetime. Regular dental checkups and good oral hygiene are key to maintaining the health of your treated tooth." },
    ]
  },
  {
    type: "h2",
    text: "Save Your Tooth with Root Canal Treatment",
    content: [
      { type: "paragraph", text: "If you're experiencing tooth pain, swelling, or sensitivity, don’t wait—contact our Maadi dental clinic today for a consultation. Our expert team is here to provide gentle, effective root canal treatment that saves your tooth and restores your smile. Schedule your appointment now to protect your dental health." },
      {
          type: "paragraph",
          parts: [
            { text: "Learn more about our " },
            { link: { href: "/servicesDetails/5", text: " Restorative Dentistry Services." } },
          ]
      },
      {
          type: "paragraph",
          parts: [
            { text: "Discover more about root canal treatments from the " },
            { link: { href: "https://www.aae.org/patients/root-canal-treatment/what-is-a-root-canal/", text: "American association of endodontists" } },
          ]
        },
    ]
  },
],

  features: [
  {
    icon: "/img/services/ser-fea-icon-1.png",
    title: "Pain-Free Root Canal Therapy",
    items: [
      "Our clinic uses advanced techniques and anesthesia to ensure your root canal treatment is comfortable and painless.",
      "We specialize in gentle root canal procedures that preserve your natural tooth and prevent future dental issues.",
      "Our team provides post-treatment care and follow-ups to ensure the long-term success of your root canal therapy."
    ]
  },
  {
    icon: "/img/services/ser-fea-icon-2.png",
    title: "Advanced Endodontic Technology",
    items: [
      "We use state-of-the-art digital imaging and 3D scanning to precisely diagnose and treat root canal infections.",
      "Our clinic is equipped with the latest dental tools to ensure minimally invasive and highly effective root canal treatments.",
      "We focus on patient comfort and long-term results, using high-quality materials for fillings and crowns to protect your treated tooth."
    ]
  }
],
    // chartData: [
    //   { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    //   { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 }
    // ],
    testimonials: {
      text: "دكتور ممتاز وشغل باتقان وبدون الم ربنا يباركله ويحفظه",
      author: "Rabab Zain",
      position: "",
      image: ""
    },
    downloadsTitle: "Free Cosmetic Dentistry Guides.",
    downloads: [
      { icon: "/img/icon/pdf.png", title: "Comprehensive Guide to Root Canal Treatment – Save Your Tooth", size: "57KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Understanding Root Canals – Free Downloadable Patient Guide", size: "87KB", link: "#" },
      { icon: "/img/icon/pdf.png", title: "Root Canal Therapy Explained – How to Relieve Pain and Protect Your Tooth", size: "112KB", link: "#" }
    ],
    relatedServices: [
      { id: 1, icon: "/img/services/more-ser-6.png", title: "Cosmetic Dentistry" },
      { id: 2, icon: "/img/services/more-ser-1.png", title: "Dental Implants" },
      { id: 3, icon: "/img/services/more-ser-2.png", title: "Pediatric Dentistry" },
      { id: 4, icon: "/img/services/more-ser-3.png", title: "Orthodontics" },
      { id: 5, icon: "/img/services/more-ser-4.png", title: "Restorative Dentistry" }
    ]
  },
];





const ServicesDetails = () => {
   const { id } = useParams(); // Extract ID from URL
   const service = servicesData.find(service => service.id === parseInt(id)); // Find matching service by ID

   if (!service) {
      return <p>Service not found</p>; // Handle case where service is not found
   }

   return (
       <>
        <HomeFourFiveNavBar />
         <CommonPageHeader title={service.title} subtitle="Details" />
         <ServicesDetailsArea service={service} /> {/* Pass service details */}
         <Footer />
      </>
   );
};

export default ServicesDetails;
