import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../../pages/HomeFour/HomeFourFooter/HomeFourFooter';
import CalculateArea from '../ServicesOne/CalculateArea/CalculateArea';
import ServicesOneHiringArea from '../ServicesOne/ServicesOneHiringArea/ServicesOneHiringArea';
import ServicesTwoAbout from './ServicesTwoAbout/ServicesTwoAbout';
import ServicesTwoArea from './ServicesTwoArea/ServicesTwoArea';
import HomeFourFiveNavBar from '../../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';

// Example of service data (this can be moved to a separate data file or API call)
const servicesData = [
  {
    id: 1,
    title: "General Dental Care",
    shortDescription: "Comprehensive dental care solutions.",
    mainImage: "/img/services/service-thumb-1.png",
    detailsPath: "/servicesDetails/1", // Directs to the specific service details page
  },
  {
    id: 2,
    title: "Cosmetic Dentistry",
    shortDescription: "Smile enhancement and cosmetic treatments.",
    mainImage: "img/services/service-details-thumb2.jpg",
    detailsPath: "/servicesDetails/2",
  },
  {
    id: 3,
    title: "Pediatric Dentistry",
    shortDescription: "Specialized care for children’s dental health.",
    mainImage: "img/services/service-details-thumb3.jpg",
    detailsPath: "/servicesDetails/3",
  },
  {
    id: 4,
    title: "Orthodontics",
    shortDescription: "Align your teeth with our expert orthodontic care.",
    mainImage: "img/services/service-details-thumb4.jpg",
    detailsPath: "/servicesDetails/4",
  },
];

const ServicesTwo = () => {
  return (
    <>
      <HomeFourFiveNavBar />
      <CommonPageHeader title="Your dental clinic in Maadi" subtitle="Services" />
      {/* Pass the dynamic services data to the ServicesTwoArea */}
      <ServicesTwoAbout />
      <ServicesTwoArea servicesData={servicesData} /> {/* Updated to pass servicesData */}
      <CalculateArea />
      <ServicesOneHiringArea />
      <Footer />
    </>
  );
};

export default ServicesTwo;
