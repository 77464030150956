import React, { useState, useEffect } from 'react';
import SingleGalleryItem from '../../../components/SingleGalleryItem/SingleGalleryItem';
import portfolioData from '../../../fakeData/portfolioData';

// Remove unnecessary unique item logic
const PortfolioThreeColGallery = () => {
    const [galleryItems, setGalleryItems] = useState(portfolioData);
    const [filterGalleryItems, setFilterGalleryItems] = useState(portfolioData);
    const [active, setActive] = useState('all');

    // Show all items by default
    useEffect(() => {
        filterItems('all');
    }, []);

    // Filter items based on the selected category
    const filterItems = (category) => {
        setActive(category);
        if (category === 'all') {
            setFilterGalleryItems(portfolioData); // Show all items
        } else {
            const remainingItems = galleryItems.filter(item => item.category === category);
            setFilterGalleryItems(remainingItems);
        }
    };

    return (
        <>
            <section className="portfolio-area pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        {/* PORTFOLIO FILTER */}
                        <div className="col-12">
                            <div className="text-center">
                                <div className="portfolio-filter mb-40">
                                    <button className={active === 'all' ? 'active' : null}
                                        onClick={() => filterItems('all')}>Show all</button>

                                    <button className={active === 'Cosmetic dentistry' ? 'active' : null}
                                        onClick={() => filterItems('Cosmetic dentistry')}>Cosmetic dentistry</button>

                                    <button className={active === 'Dental Implants' ? 'active' : null}
                                        onClick={() => filterItems('Dental Implants')}>Dental Implants</button>

                                    <button className={active === 'Pediatric Dentistry' ? 'active' : null}
                                        onClick={() => filterItems('Pediatric Dentistry')}>Pediatric Dentistry</button>

                                    <button className={active === 'Orthodontics' ? 'active' : null}
                                        onClick={() => filterItems('Orthodontics')}>Orthodontics</button>

                                    <button className={active === 'Restorative Dentistry' ? 'active' : null}
                                        onClick={() => filterItems('Restorative Dentistry')}>Restorative Dentistry</button>

                                    <button className={active === 'Root canal' ? 'active' : null}
                                        onClick={() => filterItems('Root canal')}>Root canal</button>
                                    
                                    <button className={active === 'The Clinic' ? 'active' : null}
                                        onClick={() => filterItems('The Clinic')}>The clinic</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Display all filtered gallery items */}
                    <div id="portfolio-grid" className="row row-portfolio">
                        {filterGalleryItems.map((item, index) => (
                            <SingleGalleryItem
                                key={item.id}
                                galleryItem={item}
                                index={index}
                                filterGalleryItems={filterGalleryItems}
                                column="4" // Set this to "4" for 3 items per row
                            />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default PortfolioThreeColGallery;
