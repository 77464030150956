import React from 'react';
import { Link } from 'react-router-dom';

const ServicesTwoSingleItem = ({ image, title, description, path }) => {
  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-6">
        <div className="service-box-3 mb-30 text-center">
          <div className="service-thumb">
            {/* Use the full image path provided in the prop */}
            <Link to={path}>
              <img src={image} alt={title} className="service-card-image" />
            </Link>
          </div>
          <div className="service-content-box">
            <div className="service-content">
              <h3>
                {/* Dynamic Link for the service title */}
                <Link to={path}>{title}</Link>
              </h3>
              {/* Dynamic description passed as prop */}
              <p>{description}</p>
            </div>
            {/* Link for Read More with dynamic path */}
            <Link to={path} className="service-link">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesTwoSingleItem;
