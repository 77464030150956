import React from 'react';
import { Link } from 'react-router-dom';

const ServicesOneHiringArea = () => {
    return (
        <>
            <section className="hiring-area pt-120 pb-120">
                <div className="container">
                    <div className="row no-gutters hire-bg-2">
                        <div className="col-xl-6 col-lg-6">
                            <div className="hire-img">
                                <img className="img" src="img/hire/hire1.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="hire-text">
                                <h1>Pediatric Dentistry</h1>
                                <p>Early Childhood Caries (ECC), commonly known as baby bottle tooth decay, is a serious dental condition affecting children under the age of six. Our pediatric dental clinic in Maadi offers comprehensive ECC treatment options, including fluoride varnish, dental sealants, and minimally invasive cavity fillings to prevent further tooth decay. Our goal is to preserve your child’s primary teeth, which play a critical role in proper jaw development and the alignment of permanent teeth. Early intervention is crucial to protect your child’s smile from the painful effects of tooth decay and to ensure long-term oral health.
                                </p>
                                <Link to="/contact"
                                    className="primary_btn btn-icon btn-icon-green ml-0"><span>+</span>Contact us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters hire-bg">
                        <div className="col-xl-6 col-lg-6">
                            <div className="hire-text">
                                <h1>Pediatric Dental Crowns in Maadi</h1>
                                <p>Pediatric dental crowns are critical for protecting decayed baby teeth, fractured primary teeth, or weakened enamel in young children. At our pediatric dental clinic in Maadi, we provide stainless steel crowns, zirconia crowns, and tooth-colored crowns specifically designed for children’s teeth. These crowns are ideal for children who have undergone pulp therapy, extensive cavities, or baby root canal treatments, preventing premature tooth loss and maintaining proper spacing for permanent teeth. By preserving primary teeth, pediatric crowns help ensure healthy jaw development, optimal chewing function, and clear speech articulation during childhood.
                                </p>
                                <Link to="/contact"
                                    className="primary_btn btn-icon ml-0"><span>+</span>apply today</Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="hire-img">
                                <img className="img" src="img/hire/hire2.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesOneHiringArea;