import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13827.318987985698!2d31.27655166977539!3d29.955575399999994!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145839ca0bc0f3eb%3A0x55cf5493c4589049!2sZA%20Dental%20Clinic!5e0!3m2!1sen!2seg!4v1729449965997!5m2!1sen!2seg" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"></iframe>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;


