import React from 'react';
import SingleCount from '../../../../components/SingleCount/SingleCount';

const CounterArea = () => {
   return (
      <>
         <section className="about-area pt-120 pb-80">
            <div className="container">
               <div className="row">

                  <SingleCount icon="7" counter="7" title="Expert Doctors" description="Our clinic is staffed with over 7 highly experienced dental professionals, each specializing in different areas of modern dentistry."/>
                  <SingleCount icon="83" counter="1500" title="Cases" description="We have proudly treated over 1,500 dental cases, offering solutions that range from routine check-ups to complex procedures."/>
                  <SingleCount icon="9" counter="1000" title="Smiles Restored" description="We have transformed over 1,000 smiles, helping our patients regain their confidence and oral health with personalized treatments."/>

               </div>
            </div>
         </section>
      </>
   );
};

export default CounterArea;