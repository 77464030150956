import React from 'react';
import ServicesTwoSingleItem from '../../../../components/ServicesTwoSingleItem/ServicesTwoSingleItem';

// Updated services data with different image paths for testing
const servicesData = [
  {
    id: 1,
    title: "Cosmetic dentistry",
    description: "Our teeth whitening treatments safely remove stains for a brighter, whiter smile in just one visit.",
    image: "/img/services/service-thumb-1.png", // Path for 'public' folder
  },
  {
    id: 2,
    title: "Dental Implants",
    description: "Dental implants that provide a long-lasting, natural-looking solution for missing teeth.",
    image: "/img/services/service-thumb-2.png",
  },
  {
    id: 3,
    title: "Pediatric Dentistry",
    description: "Focus on providing gentle care to your child’s dental health while making their visit easy as possible.",
    image: "/img/services/service-thumb-3.png",
  },
  {
    id: 4,
    title: "Orthodontics",
    description: "Whether you need traditional braces or prefer Invisalign, our orthodontic services offer tailored solutions to your teeth.",
    image: "/img/services/service-thumb-4.png",
  },
  {
    id: 5,
    title: "Restorative Dentistry",
    description: "Repair damaged teeth, replace missing teeth, with crowns, & bridges.",
    image: "/img/services/service-thumb-5.png",
  },
  {
    id: 6,
    title: "Root canal treatment",
    description: "Provide effective, treatments to restore damaged teeth, relieve pain, and protect your oral health.",
    image: "/img/services/service-thumb-6.png",
  },
];

const ServicesTwoArea = () => {
  return (
    <section className="servcies-area gray-bg pt-115 pb-90">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-8 col-md-12">
            <div className="section-title pos-rel mb-75">
              <div className="section-icon">
                <img
                  className="section-back-icon back-icon-left"
                  src="/img/section/section-back-icon.png" // Ensure this path is correct as well
                  alt="Section Icon"
                />
              </div>
              <div className="section-text pos-rel">
                <h5>ZA dental clinic - Pain-Free and Effective Solutions</h5>
                <h1>Best dental clinic in Maadi, Cairo.</h1>
              </div>
              <div className="section-line pos-rel">
                <img src="/img/shape/section-title-line.png" alt="Section Line" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-4">
            <div className="section-button text-end d-none d-lg-block pt-80">
              {/* <a href="/services" className="primary_btn btn-icon ml-0">
                <span>+</span>more services
              </a> */}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Render Service Cards Dynamically */}
          {servicesData.map((service) => (
            <ServicesTwoSingleItem
              key={service.id}
              image={service.image} // Make sure the image path is correct
              title={service.title}
              description={service.description} // Passing the description to the single item
              path={`/servicesDetails/${service.id}`} // Pass the dynamic path for navigation
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesTwoArea;
