import React from 'react';
import { Link } from 'react-router-dom';

const HomeFact = () => {
   return (
      <>
         <section className="fact-area fact-map primary-bg pos-rel pt-115 pb-60">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-10">
                     <div className="section-title pos-rel mb-45">
                        <div className="section-text section-text-white pos-rel">
                           <h5>Trusted Dental Experts</h5>
                           <h1 className="white-color">Always Ready to Care for Your Smile</h1>
                        </div>
                     </div>
                     <div className="section-button section-button-left mb-30">
                        <Link to="/contact" className="primary_btn btn-icon ml-0"><span>+</span>Make Appointment</Link>
                     </div>
                  </div>
                  <div className="col-lg-6 col-lg-6 col-md-8">
                     <div className="cta-satisfied">
                        <div className="single-satisfied mb-50">
                           <h1>10K+</h1>
                           <h5> <i className="fas fa-user"></i> Satisfied Patients</h5>
                           <p>Our clinic has proudly served over 1 million patients, restoring smiles and enhancing oral health with cutting-edge treatments and compassionate care. Patient satisfaction is at the heart of everything we do.</p>
                        </div>
                        <div className="single-satisfied mb-50">
                           <h1>100+</h1>
                           <h5><i className="far fa-thumbs-up"></i> Recognized for Dental Excellence</h5>
                           <p>With a track record of success, we’ve earned the trust of thousands of patients for our commitment to exceptional dental care. Our clinic has consistently been acknowledged for our treatments.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFact;