import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../pages/HomeFour/HomeFourFooter/HomeFourFooter';

import PortfolioThreeColGallery from './PortfolioThreeColGallery/PortfolioThreeColGallery';
import HomeFourFiveNavBar from '../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';

const PortfolioThreeCol = () => {
    return (
        <>
            <HomeFourFiveNavBar />
            <CommonPageHeader title="Smile Transformations: Explore Our Dental Treatment Gallery" subtitle="Portfolio" />
            <PortfolioThreeColGallery/>
            <Footer/>
        </>
    );
};

export default PortfolioThreeCol;