import React from 'react';
import { Link } from 'react-router-dom';

const BlogDetailsLeftSide = ({ title, content, details_topBar, date, author, tags }) => {
  return (
    <>
      <div className="col-lg-8">
        <article className="postbox post format-image mb-40">
          {/* Dynamic Top Bar Content (e.g., Image) */}
          {details_topBar}

          <div className="postbox__text bg-none">
            {/* Dynamic Post Metadata */}
            <div className="post-meta mb-15">
              <span>
                <i className="far fa-calendar-check"></i> {date}
              </span>
              <span>
                <Link to="/blogDetails">
                  <i className="far fa-user"></i> {author}
                </Link>
              </span>
            </div>

            {/* Dynamic Blog Title */}
            <h3 className="blog-title">{title}</h3>

            {/* Dynamic Blog Content */}
            <div className="post-text mb-20">
              {content.map((block, index) => {
                switch (block.type) {
                  case 'paragraph':
                    return <p key={index}>{block.text}</p>;
                  case 'heading':
                    // Adjust heading levels as needed
                    if (block.level === 4) {
                      return <h4 key={index}>{block.text}</h4>;
                    } else if (block.level === 3) {
                      return <h3 key={index}>{block.text}</h3>;
                    }
                    // Add more levels if needed
                    break;
                  case 'image':
                    return (
                      <div key={index} className="blog-inner-img mb-30 mt-30">
                        <img src={block.src} alt={block.alt} />
                      </div>
                    );
                  case 'blockquote':
                    return (
                      <blockquote key={index}>
                        <p>{block.text}</p>
                        {block.footer && <footer>- {block.footer}</footer>}
                      </blockquote>
                    );
                  // Add more content types if needed
                  default:
                    return null;
                }
              })}
            </div>

            {/* Dynamic Tags and Share Icons */}
            <div className="row mt-50">
              {/* <div className="col-xl-8 col-lg-8 col-md-8 mb-15">
                <div className="blog-post-tag">
                  <span>Related Tags</span>
                  {tags.map((tag, index) => (
                    <Link key={index} to="/blogs">
                      {tag}
                    </Link>
                  ))}
                </div>
              </div> */}
              <div className="col-xl-4 col-lg-4 col-md-4 mb-15">
                <div className="blog-share-icon text-left text-md-right">
                  <span>Share: </span>
                  <a href="#"><i className="fab fa-facebook-f"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-instagram"></i></a>
                  <a href="#"><i className="fab fa-google-plus-g"></i></a>
                  <a href="#"><i className="fab fa-vimeo-v"></i></a>
                </div>
              </div>
            </div>

            {/* Static or Dynamic Previous/Next Posts */}
            {/* You can make these dynamic if you have the data */}
            {/* <div className="row">
              <div className="col-12">
                <div className="navigation-border pt-50 mt-40"></div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">
                <div className="bakix-navigation b-next-post text-left mb-30">
                  <span>
                    <Link to="/blogDetails">Prev Post</Link>
                  </span>
                  <h4>
                    <Link to="/blogDetails">Previous Post Title</Link>
                  </h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 ">
                <div className="bakix-filter text-left text-md-center mb-30">
                  <Link to="/blogDetails">
                    <img src="img/icon/filter.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">
                <div className="bakix-navigation b-next-post text-left text-md-right mb-30">
                  <span>
                    <Link to="/blogDetails">Next Post</Link>
                  </span>
                  <h4>
                    <Link to="/blogDetails">Next Post Title</Link>
                  </h4>
                </div>
              </div>
            </div> */}

            {/* Author Information */}
            {/* You can make this dynamic by adding an 'authorInfo' field to your data */}
            <div className="author mt-80 mb-40">
              <div className="author-img text-center">
                <img src="img/blog/details/author.png" alt="" />
              </div>
              <div className="author-text text-center">
                <h3>{author}</h3>
                <div className="author-icon">
                  <a href="#"><i className="fab fa-facebook-f"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-behance-square"></i></a>
                  <a href="#"><i className="fab fa-youtube"></i></a>
                  <a href="#"><i className="fab fa-vimeo-v"></i></a>
                </div>
                <p>
                  {/* You can add an 'authorBio' field to your data */}
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua...
                </p>
              </div>
            </div>

            {/* Comments Section */}
            {/* You can make comments dynamic by adding a 'comments' array to your data */}
            {/* <div className="post-comments">
              <div className="blog-coment-title mb-30">
                <h2>03 Comments</h2>
              </div>
              <div className="latest-comments">
                <ul>
                  
                  <li>
                    <div className="comments-box">
                      <div className="comments-avatar">
                        <img src="img/blog/details/comments1.png" alt="" />
                      </div>
                      <div className="comments-text">
                        <div className="avatar-name">
                          <h5>Karon Balina</h5>
                          <span>19th May 2021</span>
                          <Link to="/blogDetails" className="reply">
                            <i className="fas fa-reply"></i>Reply
                          </Link>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua...
                        </p>
                      </div>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div> */}

            {/* Comment Form */}
            {/* <div className="post-comments-form">
              <div className="post-comments-title">
                <h2>Post Comments</h2>
              </div>
              <form id="contacts-form" className="conatct-post-form" action="#">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="contact-icon contacts-message">
                      <textarea
                        name="comments"
                        id="comments"
                        cols="30"
                        rows="10"
                        placeholder="Your Comments...."
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="contact-icon contacts-name">
                      <input type="text" placeholder="Your Name.... " />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="contact-icon contacts-email">
                      <input type="email" placeholder="Your Email...." />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="contact-icon contacts-website">
                      <input type="text" placeholder="Your Website...." />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <button className="primary_btn theme-btn" type="submit">
                      Post comment
                    </button>
                  </div>
                </div>
              </form>
            </div> */}
          </div>
        </article>
      </div>
    </>
  );
};

export default BlogDetailsLeftSide;
