import React from 'react';
import HomeFourSingleService from '../../../components/HomeFourSingleService/HomeFourSingleService';
import Slider from "react-slick";
import useGlobalContext from '../../../hooks/useGlobalContext';

const HomeFourServices = () => {
   const { SlickArrowLeft, SlickArrowRight } = useGlobalContext();
   const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 300,
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               infinite: true,
               arrows: false,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               arrows: false,
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            }
         }
      ]
   };
   const services = [
      {
         id: 6, // Unique ID for each service
         image: "1",
         name: "Expert fillings in Maadi.",
         title: "Root canal treatment",
         description: "Preserve your tooth and relieve pain with quick, root canal treatment"
      },
      {
         id: 1,
         image: "2",
         name: "Teeth Whitening & Veneers.",
         title: "Cosmetic Dentistry",
         description: "Enhance your smile with teeth whitening, veneers, and smile design."
      },
      {
         id: 4,
         image: "3",
         name: "Braces & Retainers",
         title: "Orthodontic Services",
         description: "Straighten your teeth for a perfect smile with our orthodontic treatment."
      },
      {
         id: 5,
         image: "4",
         name: "Fillings, Crowns & Bridges",
         title: "Restorative Dentistry",
         description: "Restore damaged teeth with fillings, crowns, and bridges."
      },
      {
         id: 3,
         image: "5",
         name: "Crowns & Space Maintainers",
         title: "Pediatric Dentistry",
         description: "Support your child's dental growth with custom dental appliances."
      },
      {
         id: 2,
         image: "6",
         name: "Restore Your Smile",
         title: "Dental Implants",
         description: "Permanent solution for missing teeth with our expert implant"
      },
   ];
   return (
      <>
         <section className="services-area services-border pos-rel pt-185 pb-160">
            <span className="h4services-bg">
               <img src="img/home4/services/services__overlay__bg.png" alt="" />
            </span>
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt="" />
                        </div>
                        <div className="section-text pos-rel">
                           <h5>Your teeth are safe with us</h5>
                           <h1>Dental Services for Healthy Smiles</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
               <Slider className='h4service-active h4_services_slider' {...settings}>
               {services.map((service, index) => (
                  <HomeFourSingleService 
                     key={index}
                     image={service.image}
                     name={service.name}
                     title={service.title}
                     description={service.description}
                     id={service.id} // Pass unique id
                  />
               ))}
            </Slider>
            </div>
         </section>
      </>
   );
};

export default HomeFourServices;
