import React from 'react';
import DoctorsTwoSingleTeam from '../../../../components/DoctorsTwoSingleTeam/DoctorsTwoSingleTeam';

const DoctorsTwoTeamArea = () => {
    return (
        <>
            <section className="team-area pt-115 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div className="section-title text-center pos-rel mb-70">
                                <div className="section-icon">
                                    <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text pos-rel">
                                    <h5>Our Team</h5>
                                    <h1>Meet Our Expert Dentists</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <DoctorsTwoSingleTeam image="7" name="Adnan Mohammed" title="Restorative dentist"  />
                        <DoctorsTwoSingleTeam image="8" name="Ahmed Ibrahim" title="Endodontist" />
                        <DoctorsTwoSingleTeam image="9" name="Fatma Mahmoud" title="Pedodontist" />
                        <DoctorsTwoSingleTeam image="10" name="Omar Abdelaziz" title="Orthodontists" />
                        <DoctorsTwoSingleTeam image="11" name="Mahmoud Khorshed" title="Oral maxillofacial surgeon" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default DoctorsTwoTeamArea;